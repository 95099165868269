/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";

import { getDashboard } from "./redux/dashboardCRUD";
import { Button, Col, Container, Row, Form, Card } from "react-bootstrap";
import DashboardCard from "./DashboardCard";
import { useFormik } from "formik";
import moment from "moment";

const DashboardMember = () => {
  const intl = useIntl();
  const admin: any = localStorage.getItem("ActiveBranch");
  const abranch = JSON.parse(admin);
  const activebranchUUID = abranch?.iBranchUUID;
  const dateFormat = abranch?.idateFormate || "YYYY-MM-DD";
  const currency = abranch?.currencySymbol || "₹";

  const date = moment().format(dateFormat);
  const defaultdate = new Date();

  const [value, setValue] = useState<any>([defaultdate, defaultdate]);
  const [apiresponse, setapiresponse] = useState<any>();
  const [isUpdated, setIsUpdated] = useState<any>(false);

  const initialState = {
    startDate: date,
    endDate: date,
  };
  const [filterValues, setFilterValues] = useState(initialState);

  const dashboard = async () => {
    const queryString = new URLSearchParams(filterValues).toString();
    // const response = await getDashboard(activebranchUUID, queryString);
    // console.log(response?.data?.data, "dashboard");
    // if (response?.status === 200) {
    //   // setIsUpdated(true);
    //   setapiresponse(response?.data?.data);
    // } else {
    //   console.log(response?.data?.replyMsg);
    // }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      console.log(values, "date");
      setFilterValues(values);
    },
  });

  const handleDateReset = (values: any) => {
    setValue([defaultdate, defaultdate]);
    formik.setFieldValue("startDate", date);
    formik.setFieldValue("endDate", date);
    // setIsUpdated(false);
  };

  const handleChange = (values: any) => {
    setValue(values);
    // console.log(values,)
    if (values && values.length !== 0) {
      formik.setFieldValue("startDate", moment(values[0]).format(dateFormat));
      formik.setFieldValue("endDate", moment(values[1]).format(dateFormat));
    }
  };

  const handleReset = () => {
    setValue([defaultdate, defaultdate]);
    formik.resetForm();
    // handleResetFilter();
    setFilterValues(initialState);
    // setIsUpdated(false);
    // getMembers();
  };

  useEffect(() => {
    dashboard();
  }, [filterValues]);

  const enquiry = intl.formatMessage({ id: "DASHBOARD.ENQUIRY" });
  const member = intl.formatMessage({ id: "DASHBOARD.MEMBER" });
  const expiry = intl.formatMessage({ id: "DASHBOARD.EXPIRY" });
  const followup = intl.formatMessage({ id: "DASHBOARD.FOLLOWUP" });
  const enrolled = intl.formatMessage({ id: "DASHBOARD.ENROLLED" });
  const employee = intl.formatMessage({ id: "DASHBOARD.EMPLOYEE" });
  const empbirth = intl.formatMessage({ id: "DASHBOARD.EMPLOYEEBIRTHDAY" });
  const membirth = intl.formatMessage({ id: "DASHBOARD.MEMBERBIRTHDAY" });
  const invoice = intl.formatMessage({ id: "DASHBOARD.INVOICE" });
  const dueamount = intl.formatMessage({ id: "DASHBOARD.DUEAMOUNT" });
  const expense = intl.formatMessage({ id: "DASHBOARD.EXPENSE" });
  const Today = intl.formatMessage({ id: "DASHBOARD.TODAY" });
  const between = intl.formatMessage({ id: "DASHBOARD.BETWEEN" });
  const total = intl.formatMessage({ id: "DASHBOARD.TOTAL" });

  return (
    <>
      {" "}
      <Container fluid className="rounded" style={{ background: "#FFFFFF" }}>
        <Card className="w-100">
          <Card.Body className="py-3">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="my-4">
                {" "}
                <Col md={3} className="mb-3">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DATERANGE",
                      })}
                    </label>

                    <DateRangePicker
                      value={value}
                      onChange={handleChange}
                      format="MM/dd/yyyy"
                      placeholder="Select Date Range"
                      placement="right"
                      style={{ margin: "2px" }}
                      onClean={handleDateReset}
                    />
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Button
                    type="submit"
                    variant="primary"
                    className="me-3 mr-4 "
                    // onClick={(e) => handleSearchClick(e)}
                    // disabled={isLoading}
                  >
                    {intl.formatMessage({
                      id: "COMMON.APPLY",
                    })}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleReset}
                    className="me-3 "
                  >
                    {intl.formatMessage({
                      id: "COMMON.RESET",
                    })}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <Row className="my-4 ">
          <Col md={3}>
            <div className="bg-light-warning py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${expiry}`
                    : ` ${expiry} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalExpiredPackage}
                color="warning"
                slug="Expired packages "
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-primary  py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${enquiry}`
                    : `${enquiry} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalEnquiry}
                color="primary"
                slug="Enquiries "
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-warning py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${followup}`
                    : `${followup} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalFollowup}
                color="warning"
                slug="Follouw-ups "
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=" "
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${enrolled}`
                    : `${enrolled} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalEnrolledPackage}
                color="success"
                slug="Enrolled Packages"
              />
            </div>
          </Col>

          <Col md={3}>
            <div className="bg-light-warning  py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${employee}`
                    : ` ${employee} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalEmployee}
                color="warning"
                slug="Employees"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-danger  py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${empbirth}`
                    : `${empbirth} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalEmployeeBirthday}
                color="danger"
                slug="Employees Birthday"
              />
            </div>
          </Col>

          <Col md={3}>
            <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={`${total} ${member}`}
                subtitle={apiresponse?.totalFixedMember}
                color="success"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-primary py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${membirth}`
                    : `${membirth} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalMemberBirthDay}
                color="primary"
                slug="Member's Birthday "
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-danger py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title={currency}
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${invoice}`
                    : `${invoice} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalReceipt}
                color="danger"
                slug="Invoices"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-warning py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title={currency}
                date={`${total} ${invoice}`}
                subtitle={apiresponse?.totalFixedReceipt}
                color="warning"
              />
            </div>
          </Col>

          <Col md={3}>
            <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title={currency}
                date={`${Today} ${dueamount}`}
                subtitle={apiresponse?.totalFixedDueAmt}
                color="success"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-danger py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title={currency}
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${expense}`
                    : `${expense} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalexpense}
                color="danger"
                slug="Expenses"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-primary py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title={currency}
                date={`${total} ${expense}`}
                subtitle={apiresponse?.totalFixedExpense}
                color="primary"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardMember;
