import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { resetMemberPassword } from "../../redux/AuthCRUD";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import swal from "sweetalert";

const initialValues = {
  otp: "",
  password: "",
  confirm_password: "",
};

const forgotPasswordSchema = Yup.object().shape({
  otp: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export function ResetPassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<any>();
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //   console.log(values);
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        resetMemberPassword(values)
          .then((response) => {
            if (response.status === 200) {
              // console.log(response, "response---");
              swal(response?.data?.replyMsg, { icon: "success" });
              // console.log(response);
              setHasErrors(false);
              setLoading(false);
              history.push("/auth/memberlogin");
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 400) {
              const errorMsg = err.response?.data?.replyMsg?.reduce(
                (acc: any, item: any) => {
                  // Merge each object into the accumulator
                  return { ...acc, ...item };
                },
                {}
              );
              // console.log(errorMsg.otp);
              setError(errorMsg);
              //   console.log(errorMsg);
              // setHasErrors(true);
              setStatus(errorMsg.otp);
            } else {
              // console.log(err);
              // setHasErrors(true);
              setStatus("An unexpected error occurred. Please try again.");
            }
          })
          .finally(() => {
            setLoading(false);
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Reset Your's Password </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            Enter otp and change your password.
          </div>
          {/* end::Link */}
        </div>

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="required form-label fw-bolder text-gray-900 fs-6">
            OTP
          </label>
          <input
            type="number"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("otp")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              //   { "is-invalid": formik.touched.otp && formik.errors.otp },
              //   {
              //     "is-valid": formik.touched.otp && !formik.errors.otp,
              //   }
            )}
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.otp}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row mb-10">
          <label className="required form-label fw-bolder text-gray-900 fs-6">
            password
          </label>
          <div className="position-relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder=""
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
                //   { "is-invalid": formik.touched.otp && formik.errors.otp },
                //   {
                //     "is-valid": formik.touched.otp && !formik.errors.otp,
                //   }
              )}
            />
            <span
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
              style={{ cursor: "pointer" }}
              onClick={() => setShowpassword(!showPassword)}
            >
              {showPassword ? (
                <FaEyeSlash className="fs-4 text-primary me-3" />
              ) : (
                <FaEye className="fs-4 text-muted me-3" />
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
          {error?.password && (
            <div className="text-danger">{error.password}</div>
          )}
        </div>
        <div className="fv-row mb-10">
          <label className="required form-label fw-bolder text-gray-900 fs-6">
            confirm password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("confirm_password")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              //   { "is-invalid": formik.touched.otp && formik.errors.otp },
              //   {
              //     "is-valid": formik.touched.otp && !formik.errors.otp,
              //   }
            )}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert ">{formik.errors.confirm_password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            <span className="indicator-label">Submit</span>
            {loading && (
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{" "}
        </div>
        {/* end::Form group */}
      </form>
    </div>
  );
}
