import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import InvoiceList from "../InvoiceList";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetUserInvoices: "[GetUserInvoices] Action",
};

const initialAuthState: ICenterState = {
  Userinvoices: undefined,
};

export interface ICenterState {
  Userinvoices?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Userinvoices"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetUserInvoices: {
        const Userinvoices = action.payload?.Userinvoices;
        return { ...state, Userinvoices };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetUserInvoices: (Userinvoices: string) => ({
    type: actionTypes.GetUserInvoices,
    payload: { Userinvoices },
  }),
};
