/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import ExpenseCategoryModal from "../../expenceCategory/component/modal/ExpenseCategoryModal";

import * as SMS from "../redux/SMSREDUX";

import Logo from "../../../../../../src/loading_assests/logormbg.png";
import swal from "sweetalert";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";

import { CSVLink } from "react-csv";
// import PackagePeriodModal from "./modal/ExpenseCategoryModal";
import { KTSVG, StatusBadge } from "../../../../../_metronic/helpers";
import {
  GetExpenses,
  Update_ExpenseCategory_Status,
  detailofUpdateExpense,
} from "../redux/SMSCRUD";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import ExpenseModal from "./modal/SupplementModal";
// import "rsuite/DateRangePicker/styles/index.css";
// import DateRangePicker from "rsuite/esm/DateRangePicker";
import moment from "moment";
import EditSms from "./modal/EditSms";
import configdata from "../../../../../config.json";
import Pagination from "../../../Genric componant/Pagination";
import permission from "../../../Genric componant/permissions/permission";
import { exportToXLS } from "../../../Genric componant/exportToxls/exportToxls";
import Loading from "../../../Genric componant/loading/Loading";
// import SupplementModal from "./modal/SupplementModal";
// import InvoicePage from "./InvoicePage";
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const SMSList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "dtModifiedDate", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const sms = useSelector((state: any) => state?.SMS?.SMS?.data);

  // active branch data taken from local storage
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchID = ActiveBranch.iBranchID;
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("sms");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });
  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>(initialDates);

  const [UpdateUser, setUpdateUser] = useState<any>({
    expenseCatUUID: "",
    branchId: "",
    name: "",
    Status: "",
  });

  //page state
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  let initialState = {
    status: "",
    page_limit: pageLimit,
    page_no: 1,
    branchID: ActiveBranch.iBranchID,
    order_key: "usersmsmessageTemplateID",
    order_value: "desc",
    search: "",
  };

  const [show, setShow] = useState<any>(false);
  const [activeCatagory, setActiveCatagory] = useState<any>();
  const [activeMember, setActiveMember] = useState<any>();
  const [activeProduct, setActiveProduct] = useState<any>();
  const handleShow = () => setShow(true);

  const [isUpdate, setisUpdate] = useState(false);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  const [branch_id, setBranch_id] = useState<any>("0");
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // for error
  const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  const [searchValues, setSearchValues] = useState(initialState);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [printData, setPrintData] = useState<any>();
  const [TBLData, setTBLData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  //define column of listing
  const ExpenseCatRowTitle = `${intl.formatMessage({
    id: "MODULE.SMS.MESSAGETyPE",
  })}`;
  const ExpenseIdRowTitle = `${intl.formatMessage({
    id: "MODULE.SMS.TEMPLATEID",
  })}`;
  const messageROW = `${intl.formatMessage({
    id: "MODULE.SMS.MESSAGE",
  })}`;
  const ExpenseDateRowAction = `${intl.formatMessage({
    id: "COMMON.STATUS",
  })}`;

  const ExpenseAmountRowAction = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;

  //download fileName
  const downloadfileName = "SMS_" + Date.now();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      setFilterValues(values);

      setPage(1);
    },
  });
  const getExpenseCategory = useCallback(() => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      GetExpenses({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        // console.log(data, "data");
        if (data.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(SMS.actions.GetSMS(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

  useEffect(() => {
    getExpenseCategory();
  }, [getExpenseCategory]);

  useEffect(() => {
    setFilteredData(sms?.data);
    handleExports();
  }, [sms?.data]);

  // update Role Status
  const UpdateUserStatus = (prop: any) => {
    swal({
      title: `${intl.formatMessage({ id: "COMMON.AREYOUSURE" })}`,
      text: intl.formatMessage({ id: "COMMON.NOTPERFORMOPERATION" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SUBMIT" }),
      ], //["Cancel", "Submit"],
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const Update_user = {
          branchID: branch_id,
          uuid_array: checkedRows,
          select_type: "",
          unchecked_uuid_array: [],
          status: prop.status,
        };
        if (prop.status !== "2") {
          Update_ExpenseCategory_Status(Update_user)
            .then((data: any) => {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              setCheckedRows([]);
              //getting all roled again
              setCheckedRows([]);
              setIsAllRowsChecked(false);
              getExpenseCategory();
            })
            .catch(() => {
              // swal("Somthing Went Wrong!");
              swal(
                `${intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" })}`
              );
            });
        } else {
          //   Delete_ExpenseCategory(Update_user)
          //     .then((data: any) => {
          //       //swal(`Proof! Selected Users Is ${prop.message}!`, {
          //       swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
          //         icon: "success",
          //       });
          //       setCheckedRows([]);
          //       //getting all roled again
          //       getExpenseCategory();
          //     })
          //     .catch(() => {
          //       swal(intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" }));
          //     });
        }
      } else {
        swal(`${intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" })}`);
      }
    });
  };

  //handle check box

  const handleRowCheckboxChange = (rowId: any, branchId: any) => {
    setBranch_id(branchId);
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  // handle all check box
  const handleSelectAllCheckboxChange = () => {
    setBranch_id(ActiveBranch.iBranchID);
    if (isAllRowsChecked) {
      setCheckedRows([]);
      setIsAllRowsChecked(false);
    } else {
      const allRowIds =
        sms?.data?.map((element: any) => element.usersmsmessageTemplateUUID) ||
        [];
      //console.log('packageperiods====',packageperiods);
      //console.log('allRowIds====',allRowIds);
      setCheckedRows(allRowIds);
      setIsAllRowsChecked(true);
    }
  };
  const selectPageHandler = (selectedPage: any) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPages &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };
  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialState);
  };

  const handleExports = () => {
    const tableRows = sms?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [ExpenseIdRowTitle]: element.dlt_template_id,
        [ExpenseCatRowTitle]: element.mesaageType,
        [messageROW]: element.vMessage,
        [ExpenseDateRowAction]:
          element.eStatus === 1
            ? intl.formatMessage({ id: "COMMON.ACTIVE" })
            : intl.formatMessage({ id: "COMMON.INACTIVE" }),
      };
    });

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.text(`${intl.formatMessage({ id: "COMMON.SMS" })}`, 10, 10);
    const tableRows = sms.data.map((element: any, index: number) => {
      // console.log(element, "dsjksdf");
      return [
        index + 1,
        element.dlt_template_id,
        element.mesaageType,
        element.vMessage,
        element.eStatus === 1
          ? intl.formatMessage({ id: "COMMON.ACTIVE" })
          : intl.formatMessage({ id: "COMMON.INACTIVE" }),
        /*new Date(element.dtCreated_Date).toLocaleString("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }),*/
      ];
    });
    doc.autoTable({
      head: [
        [
          "#",
          ExpenseIdRowTitle,
          ExpenseCatRowTitle,
          messageROW,
          ExpenseDateRowAction, //"Created Date"
          ,
        ],
      ],
      body: tableRows,
      startY: 50,
    });

    doc.save(downloadfileName + ".pdf");
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    setPage(1);
  };

  const handleRefreshButton = () => {
    // setIsLoading(true);
    // setCheckedRows([]);
    // setPage(1);
    // setPageLimit(pageDefaultValue);
    setFilterValues(initialState);
    setSortConfig({ key: "dtModifiedDate", direction: "desc" });
    // GetExpenses({
    //   ...searchValues,
    //   page_no: 1,
    //   page_limit: pageDefaultValue,
    //   order_key: "usersmsmessageTemplateID",
    //   order_value: sortConfig.direction,
    // }).then((data: any) => {
    //   dispatch(SMS.actions.GetSMS(data));
    //   setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
    //   setIsLoading(false);
    // });
  };

  const getDetailsForUpdate = async (branchID: any, memberuid: any) => {
    const response = await detailofUpdateExpense(branchID, memberuid);
    setUpdateUser(response?.data?.data);
    // console.log(response, "detailsof");
  };
  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  return (
    <>
      <div className="alert alert-primary" role="alert">
        <h2 className="text-gray-600">
          {intl.formatMessage({ id: "MODULE.SMS.MESSAGE.HEADING1" })}
        </h2>
      </div>
      <div className="alert alert-primary" role="alert">
        <h2 className="text-gray-600">
          {intl.formatMessage({ id: "MODULE.SMS.MESSAGE.HEADING2" })}
        </h2>
        <p className="text-gray-600">{configdata.smsHeading}</p>
      </div>
      {/* Filter Section Start of Package Period List */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={3} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.SEARCH",
                          })}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.ENTERSEARCH",
                          })}
                          onChange={formik.handleChange}
                          value={formik.values.search}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={3} className="mb-4">
                      <Form.Group controlId="status">
                        <Form.Label>
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </Form.Label>

                        <Form.Control
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.status}
                        >
                          <option value="">
                            {intl.formatMessage({ id: "COMMON.ALL" })}
                          </option>
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col
                      md={3}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        className="me-3 mr-4"
                      >
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.APPLY",
                        })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.RESET",
                        })}
                      </Button>
                    </Col>
                    {/* Rest of the form fields */}
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex  flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>

                    <>
                      <button
                        type="button"
                        className={`btn btn-success me-3 ${
                          UserType === "employee" && edit === 0 && "d-none"
                        }`}
                        onClick={() =>
                          UpdateUserStatus({
                            status: "1",
                            message: `${intl.formatMessage({
                              id: "COMMON.ACTIVATED",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className={`btn btn-light-warning me-3 ${
                          UserType === "employee" && edit === 0 && "d-none"
                        }`}
                        onClick={() =>
                          UpdateUserStatus({
                            status: "0",
                            message: `${intl.formatMessage({
                              id: "COMMON.INACTIVATED",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className={`btn btn-danger me-3 d-none ${
                          UserType === "employee" && del === 0 && "d-none"
                        }`}
                        onClick={() =>
                          UpdateUserStatus({
                            status: "2",
                            message: `${intl.formatMessage({
                              id: "COMMON.DELETE",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.DELETE" })}
                      </button>
                    </>
                  </>
                ) : (
                  <>
                    <span
                      className="min-w-40px mb-3 d-flex  align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex  flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={handleRefreshButton}
              >
                {" "}
                {intl.formatMessage({ id: "COMMON.REFRESH" })}
              </button>
              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>

                  <Button
                    variant="primary"
                    className="d-none"
                    onClick={() => {
                      handleShow();
                      setisUpdate(false);
                    }}
                  >
                    {intl.formatMessage({
                      id: "MODULE.EXPENSECATEGORY.ADD",
                    })}
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename={downloadfileName + ".csv"}
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Button
                    variant="primary"
                    className="d-none"
                    onClick={() => {
                      handleShow();
                      setisUpdate(false);
                    }}
                  >
                    {intl.formatMessage({
                      id: "MODULE.SUPPLIMENT.ADDSUPP",
                    })}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* modal start */}
        {/* modal start */}
        <EditSms
          show={show}
          setShow={setShow}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          activeCatagory={activeMember}
          activeProduct={activeProduct}
          getExpenseCategory={getExpenseCategory}
        />
        {/* <Main show={show} setShow={setShow}/> */}

        {/* modal End */}

        {/* begin::Body */}

        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className=" mobilewidth">
                    <div className="mobileViewalign w-100">
                      <div className=" selectAll ">
                        {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                      </div>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          checked={isAllRowsChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </div>
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dlt_template_id"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseIdRowTitle}
                      {sortConfig.key !== "dlt_template_id" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dlt_template_id" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dlt_template_id" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="mesaageType"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseCatRowTitle}
                      {sortConfig.key !== "mesaageType" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "mesaageType" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "mesaageType" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="vMessage"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {messageROW}
                      {sortConfig.key !== "vMessage" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "vMessage" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "vMessage" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eStatus"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseDateRowAction}
                      {sortConfig.key !== "eStatus" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eStatus" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eStatus" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {ExpenseAmountRowAction}
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : UserType === "employee" && view === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center text-danger fs-4">
                      {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    const isChecked = checkedRows.includes(
                      element.usersmsmessageTemplateUUID
                    );
                    return (
                      <>
                        <tr key={index} className="fs-5">
                          <td
                            data-label={intl.formatMessage({
                              id: "COMMON.SELECT",
                            })}
                          >
                            <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                              <input
                                className="form-check-input widget-9-check"
                                type="checkbox"
                                value="1"
                                checked={isChecked}
                                onChange={() =>
                                  handleRowCheckboxChange(
                                    element.usersmsmessageTemplateUUID,
                                    element.iBranchID
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td data-label={ExpenseIdRowTitle}>
                            {element.dlt_template_id}
                          </td>
                          <td data-label={ExpenseCatRowTitle}>
                            {element.mesaageType}
                          </td>
                          <td
                            data-label={messageROW}
                          >{`${element.vMessage}`}</td>
                          <td data-label={ExpenseDateRowAction}>
                            {StatusBadge(element.eStatus)}
                          </td>

                          <td data-label={ExpenseAmountRowAction}>
                            <div className="d-flex flex-shrink-0 floatRight">
                              {/* <span
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                  onClick={() => {
                                    handlePrintDetail(
                                      ActiveBranchID,
                                      element.usersmsmessageTemplateUUID
                                    );
                                  }}
                                >
                                  <i className="bi bi-eye"></i>
                                </span> */}
                              <a
                                href="#g"
                                className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                  UserType === "employee" &&
                                  edit === 0 &&
                                  "d-none"
                                }`}
                                onClick={() => {
                                  getDetailsForUpdate(
                                    ActiveBranchID,
                                    element.usersmsmessageTemplateUUID
                                  );
                                  setisUpdate(true);
                                  setShow(true);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/art/art005.svg"
                                  className="svg-icon-3"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className=" mobilewidth">
                    <div className="mobileViewalign w-100">
                      <div className=" selectAll ">
                        {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                      </div>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          checked={isAllRowsChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </div>
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dlt_template_id"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseIdRowTitle}
                      {sortConfig.key !== "dlt_template_id" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dlt_template_id" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dlt_template_id" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="mesaageType"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseCatRowTitle}
                      {sortConfig.key !== "mesaageType" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "mesaageType" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "mesaageType" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="vMessage"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {messageROW}
                      {sortConfig.key !== "vMessage" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "vMessage" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "vMessage" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eStatus"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseDateRowAction}
                      {sortConfig.key !== "eStatus" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eStatus" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eStatus" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {ExpenseAmountRowAction}
                  </th>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}
          {/* <div className="row">
              <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
              <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <div id="kt_table_users_paginate">
                  <ul className="pagination">
                    <li className="page-item previous">
                      <a
                        className={`page-link page-text me-5 paginate_button page-item previous ${
                          page === 1 ? "disable" : ""
                        } `}
                        style={{ cursor: "pointer" }}
                        onClick={() => selectPageHandler(page - 1)}
                      >
                        {intl.formatMessage({ id: "COMMON.PREVIOUS" })}
                      </a>
                    </li>
                    {[...Array(totalPages)].map((_, i) => {
                      return (
                        <li
                          className={
                            page === i + 1 ? "page-item active" : "page-item"
                          }
                          onClick={() => selectPageHandler(i + 1)}
                          key={i}
                        >
                          <a
                            className="page-link"
                            style={{ cursor: "pointer" }}
                          >
                            {i + 1}
                          </a>
                        </li>
                      );
                    })}

                    <li
                      className={`paginate_button page-item next ${
                        page === totalPages ? "disable" : ""
                      } `}
                    >
                      <a
                        className="page-link page-text"
                        style={{ cursor: "pointer" }}
                        onClick={() => selectPageHandler(page + 1)}
                      >
                        {intl.formatMessage({ id: "COMMON.NEXT" })}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          {filteredData && filteredData.length > 0 && (
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
          {/* end:: pagination */}
        </div>

        {/* begin::Body */}
      </div>
    </>
  );
};

export default SMSList;
