import clsx from "clsx";
import { FC, useEffect } from "react";
import { KTSVG } from "../../../helpers";
import { HeaderUserMenu, QuickLinks } from "../../../partials";
import { RootState } from "../../../../setup";
import { Languages } from "./Languages";
import { useLayout } from "../../core";
import { shallowEqual, useSelector } from "react-redux";
// import { Dropdown3 } from "../../../partials/content/dropdown/Dropdown3";
import HeaderBranchMenu from "../../../partials/layout/header-menus/HeaderBranchMenu";
import { useLang } from "../../../i18n/Metronici18n";
import { getallmodules } from "./redux/HeadersCRUD";
// import swal from "sweetalert";
// import { Modal } from "react-bootstrap";
// import { useCallback } from "react";
// import { boolean } from "yup";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  toolbarButtonIconSizeClass = "svg-icon-1";

const Topbar: FC = () => {
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  // console.log("user", user);
  const { config } = useLayout();

  const selectedbranch: any = useSelector(
    (state: any) => state.auth.selectedBranch
  );
  let activeBranch: any = localStorage.getItem("ActiveBranch");
  const selectedActiveBranch = JSON.parse(activeBranch);
  let data1: any = localStorage?.getItem("activeUserType");
  const userType = JSON.parse(data1);
  // console.log(selectedbranch, ",,,,,,,,,,,selected bracnhc");
  // console.log("aaaaaaaaaaa", selectedActiveBranch);

  const languagesnew: any = useSelector(
    (state: any) => state.headers.languageList
  );
  const lang = useLang();
  const currentLanguage = languagesnew?.find(
    (x: any) => x.languageSlug === lang
  );
  localStorage.setItem("setLanguage", JSON.stringify(currentLanguage));
  // console.log(currentLanguage, "here is langsas");

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* begin::User */}

      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_language_menu_toggle"
      >
        {/* begin::Toggle */}

        <div
          className={clsx(
            "d-flex align-items-center btn  btn-active-light-primary",
            //toolbarUserAvatarHeightClass,
            //'border border-primary', // Add a border
            "rounded-3", // Apply rounded corners
            "p-3", // Add padding
            "cursor-pointer" // Change cursor on hover
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <div className="symbol symbol-100px me-5 ">
            {currentLanguage?.languageName === undefined
              ? "English"
              : currentLanguage?.languageName}
          </div>
        </div>
        {/* end::Toggle */}
        <Languages />
      </div>

      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_Branch_menu_toggle"
      >
        {/* begin::Toggle */}

        <div
          className={clsx(
            "d-flex align-items-center btn  btn-active-light-primary",
            //toolbarUserAvatarHeightClass,
            //'border border-primary', // Add a border
            "rounded-3", // Apply rounded corners
            "p-3", // Add padding
            "cursor-pointer" // Change cursor on hover
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {/* select Active Branch Bar */}
          {/* <div className='symbol symbol-100px me-5 '>
            {selectedbranch===undefined ? ( selectedActiveBranch!==null? selectedActiveBranch?.vBranch :'Select Your Branch' ): selectedbranch?.vBranch}
           
          </div> */}

          <div className="symbol symbol-100px me-5 ">
            {/* {selectedbranch===undefined ? ( selectedActiveBranch!==null? selectedActiveBranch?.vBranch :'Select Your Branch' ): selectedbranch?.vBranch} */}
            {selectedbranch === undefined
              ? selectedActiveBranch !== null
                ? selectedActiveBranch?.vBranch
                : "Select Your Branch"
              : selectedbranch?.vBranch}
          </div>
        </div>
        {/* end::Toggle */}
        {userType !== "member" && <HeaderBranchMenu />}
      </div>
      {/* end::User */}
      {/* Activities */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* begin::Drawer toggle */}
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary",
            toolbarButtonHeightClass
          )}
          id="kt_activities_toggle"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen032.svg"
            className={toolbarButtonIconSizeClass}
          />
        </div>
        {/* end::Drawer toggle */}
      </div>
      {/* Quick links */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary",
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen025.svg"
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
        {/* end::Menu wrapper */}
      </div>

      {/* CHAT */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* begin::Menu wrapper */}

        {/* end::Menu wrapper */}
      </div>

      {/* NOTIFICATIONS */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* begin::Menu- wrapper */}

        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <div className="symbol symbol-100px me-5">
            <div
              className="symbol-label fs-2 fw-semibold bg-danger text-inverse-danger"
              style={{
                height: "40px",
                width: "40px",
              }}
            >
              {user.name?.substring(0, 1).toUpperCase()}
            </div>
          </div>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === "menu" && (
        <div
          className="d-flex align-items-center d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className="svg-icon-1"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
