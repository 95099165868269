import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import InvoiceList from "../InvoiceList";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetMemberlogAttendences: "[GetMemberlogAttendences] Action",
};

const initialAuthState: ICenterState = {
  UserMemberlogattendences: undefined,
};

export interface ICenterState {
  UserMemberlogattendences?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["UserMemberlogattendences"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetMemberlogAttendences: {
        const UserMemberlogattendences =
          action.payload?.UserMemberlogattendences;
        return { ...state, UserMemberlogattendences };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetMemberlogAttendences: (UserMemberlogattendences: string) => ({
    type: actionTypes.GetMemberlogAttendences,
    payload: { UserMemberlogattendences },
  }),
};
