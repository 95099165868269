/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { useLayout } from "../core";

const Footer: FC = () => {
  const data: any = localStorage.getItem("footer");
  let footer = [];
  if (data !== null && data !== "undefined") {
    footer = JSON.parse(data);
  }
  // useEffect(() => {
  //   // const response =await
  // }, []);
  const { classes } = useLayout();
  // console.log("hey i am footer");
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {footer?.map((item: any, index: any) => {
          // console.log(item, "footer");
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: item?.superadminValue }}
            />
          );
        })}
        {/* begin::Copyright */}
        {/* <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">
            {new Date().getFullYear()} &copy;
          </span>
          Copyright © 2020-2021
          <a href="#" className="text-gray-800 text-hover-primary ps-1">
            Automize Gym
          </a>
          All rights reserved.
        </div> */}
        {/* end::Copyright */}
        {/* begin::Nav */}
        {/* <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a href="#" className="menu-link ps-0 pe-2">
              About
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link pe-0 pe-2">
              Contact
            </a>
          </li>
          <li className="menu-item">
            <a href="#" className="menu-link pe-0">
              Purchase
            </a>
          </li>
        </ul> */}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
