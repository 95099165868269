import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetAddON: "[GetAddON] Action",
};

const initialAuthState: ICenterState = {
  addONS: undefined,
};

export interface ICenterState {
  addONS?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["addONS"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetAddON: {
        const addONS = action.payload?.addONS;
        return { ...state, addONS };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetAddON: (addONS: string) => ({
    type: actionTypes.GetAddON,
    payload: { addONS },
  }),
};
