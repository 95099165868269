import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
// import { GetBranches } from '../modules/branches/redux/BranchesCRUD';
import { GetActiveBranches } from '../modules/auth/redux/AuthCRUD';

import * as branch from '../modules/auth/redux/AuthRedux'

function useFetchBranches() {
  const dispatch = useDispatch();

  const getActiveBranches = useCallback(() => {
    GetActiveBranches().then((response) => {
      if (response.status === 200) {
        if (response.data.data.length !== 0) {
          dispatch(branch.actions.setBranches(response.data.data));
        } else {
          dispatch(branch.actions.setBranches([]));
        }
      }
    });
  }, [dispatch]);

  useEffect(() => {
    getActiveBranches();
  }, [getActiveBranches]);

  return getActiveBranches;
}

export default useFetchBranches;
