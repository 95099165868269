import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

// Updated ActionTypes Object
export const actionTypes = {
  GetEmail: "[GetEmail] Action",
};

// Updated Initial State
const initialAuthState: IPackagePeriodState = {
  Email: undefined,
};

// Updated Department State Interface
export interface IPackagePeriodState {
  Email?: any;
}

// Updated Reducer Function
export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Email"] },
  (
    state: IPackagePeriodState = initialAuthState,
    action: ActionWithPayload<IPackagePeriodState>
  ) => {
    switch (action.type) {
      case actionTypes.GetEmail: {
        const Email = action.payload?.Email;
        return { ...state, Email };
      }
      default:
        return state;
    }
  }
);

// Updated Actions Object
export const actions = {
  GetEmail: (Email: any) => ({
    type: actionTypes.GetEmail,
    payload: { Email },
  }),
};
