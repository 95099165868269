import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_SMS = `${API_URL}/v1/message/email/list`;
export const UPDATE_SMS = `${API_URL}/v1/message/email/update/`;
export const DETAIL_UPDATE_SMS = `${API_URL}/v1/message/email/details`;
export const UPDATE_SMS_STATUS = `${API_URL}/v1/message/email/update_status`;
// Get all sms
export function GetExpenses(data: any) {
  return axios.post(GET_ALL_SMS, data).catch((error) => {
    return error.response;
  });
}
export function UpdateExpenseCategory(body: any) {
  return axios.put(UPDATE_SMS, body).catch((error) => {
    return error.response;
  });
}
export function detailofUpdateExpense(activebranchid: any, expensesuuid: any) {
  return axios
    .get(`${DETAIL_UPDATE_SMS}/${activebranchid}/${expensesuuid}`)
    .catch((error) => {
      return error.response;
    });
}
// Update department status
export function Update_ExpenseCategory_Status(body: any) {
  return axios.patch(UPDATE_SMS_STATUS, body).catch((error) => {
    return error.response;
  });
}
