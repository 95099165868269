import { FC } from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
// import { GetLanguageFile } from "../layout/components/header/redux/HeadersCRUD";
// import { actions } from "../layout/components/header/redux/HeadersRedux";
// import { useDispatch } from "react-redux";
// import * as headerRedux from "../layout/components/header/redux/HeadersRedux";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/de";
import "@formatjs/intl-relativetimeformat/locale-data/es";
import "@formatjs/intl-relativetimeformat/locale-data/fr";
import "@formatjs/intl-relativetimeformat/locale-data/ja";
import "@formatjs/intl-relativetimeformat/locale-data/zh";
// import deMessages from './messages/de.json'
// import enMessages from './messages/en.json'
// import esMessages from './messages/es.json'
// import frMessages from './messages/fr.json'
// import jaMessages from './messages/ja.json'
// import zhMessages from './messages/zh.json'

import English from "./translation/en/language.json";
import Hindi from "./translation/Hi/language.json";
import Spanish from "./translation/sp/language.json";

// const allMessages = {
//   de: deMessages,
//   en: enMessages,
//   es: esMessages,
//   fr: frMessages,
//   ja: jaMessages,
//   zh: zhMessages,
// }
// const allLanguage = {
//   en: English,
//   hi: Hindi,
//   sp: Spanish,
// };

const I18nProvider: FC = ({ children }) => {
  const locale = useLang();
  // console.log(locale, "local");
  //const locale = navigator.language
  let lang = null;
  if (locale === "en") {
    lang = English;
  } else {
    if (locale === "hi") {
      lang = Hindi;
    } else {
      lang = Spanish;
    }
  }

  // const [message, setmessage] = useState(null);
  // const dispatch = useDispatch();
  // const languageJson = useSelector((state: any) => state.headers.languagejson)

  // useEffect(() => {
  //   GetLanguageFile(locale).then((response) => {
  //     dispatch(headerRedux.actions.SetLanguageFile(response?.data));
  //     setmessage(response?.data);
  //   });
  // });

  return (
    // <IntlProvider locale={locale} messages={lang}>
    //   {children}
    // </IntlProvider>
    <IntlProvider locale={locale} messages={lang}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
