import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ShowPlanModal = ({ isModalOpen, selectedPlanData,setIsModalOpen }:any) => {
  return (
    <Modal show={isModalOpen} onHide={()=>setIsModalOpen(false)} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{selectedPlanData?.plan?.membershipTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='p-5'>
          <div className='text-center mb-5'>
            <h2 className='fw-bold'>{selectedPlanData?.membershipTitle}</h2>
            <p className='text-muted fs-5'>{selectedPlanData?.description}</p>
            <p className='text-primary fw-bold fs-3'>
              ₹{selectedPlanData?.price} / {selectedPlanData?.days} days
            </p>
          </div>
          <ul className='list-group mb-5 '>
            <li className='list-group-item py-4'>
              <span className='fw-bold'>Max Inquiries Allowed:</span>{' '}
              {selectedPlanData?.MaxInquiry}
            </li>
            <li className='list-group-item py-4'>
              <span className='fw-bold'>Max Members Allowed:</span>{' '}
              {selectedPlanData?.maxMember}
            </li>
            <li className='list-group-item py-4'>
              <span className='fw-bold'>Branches Allowed:</span>{' '}
              {selectedPlanData?.noOfBrachAllow}
            </li>
          </ul>
          <div>
            <h4 className='text-center mb-4'>What's included:</h4>
            <ul className='list-group'>
              {selectedPlanData?.modules?.map((item:any,index:any) => (
                <li
                  key={index}
                  className='list-group-item d-flex justify-content-between align-items-center py-4'
                >
                  {item.moduleName}
                  <span
                    className={`badge ${
                      item.moduleName ? 'bg-success' : 'bg-danger'
                    } rounded-pill`}
                  >
                    {item.moduleName ? 'Included' : 'Not Included'}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={()=>setIsModalOpen(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowPlanModal;
