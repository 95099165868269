/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { verfiyOTP } from '../redux/AuthCRUD'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import * as auth from '../redux/AuthRedux'

const loginSchema = Yup.object().shape({
    otp: Yup.string()
        .min(6, "OTP should be 6 numbers")
        .max(6, 'OTP should be 6 numbers')
        .required('OTP is required'),
})


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
export function OtpVerify(props: any) {
    const [loading, setLoading] = useState(false)
    const { user_id } = props.location.state;
    const dispatch = useDispatch();
    let history = useHistory();
    const formik = useFormik({
        initialValues: {
            otp: '',
            user_uuid: user_id,
        },
        validationSchema: loginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setTimeout(() => {
                console.log(values);
                verfiyOTP(values).then((responce) => {
                    const { role_id, access_token, plans, vFirstName, vLastName, vEmail
                        , iAdminID
                    } = responce.data.data;
                    //creta temp veriable
                    const plan = plans[0]?.plan;
                    const planData = { plan }
                    const userData = {
                        user_first_name: vFirstName,
                        user_last_name: vLastName,
                        user_email: vEmail,
                        user_country_code: '+91',
                        user_mobile: '',
                        user_password: '',
                        country_currency_id: '101',
                        user_singup_type: 'website',
                        status: '1',
                        acceptTerms: false,
                        user_role_id: role_id,
                        plan_id: '',
                        plan_amount: '',
                    }
                    if (role_id === 2 || role_id === 3) {
                        setLoading(false)
                        if (plan.paymentStatus !== 1) {
                            history.push(`/auth/checkout`, {
                                planData, userData, userId: iAdminID,
                            })
                            toast.warning("Please complete your payment")
                        } else {
                            dispatch(auth.actions.login(access_token));
                            toast.success("Login SuccessFully");
                            console.log(access_token, role_id, "data");
                        }


                    }
                    else {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus("You do not have access")
                    }
                })
                    .catch((err) => {
                        console.log(err)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('check Login details and internet Connection ..!')
                    })
            }, 1000)
        },
    })

    return (
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
            >
                {/* begin::Heading */}
                <div className='text-center mb-10'>
                    <h1 className='text-dark mb-3'>Verify your OTP</h1>

                </div>
                {/* begin::Heading */}

                {formik.status ? (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                ) : (
                    <></>
                )}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Verification Code</label>
                    <input
                        placeholder='Enter your OTP'
                        {...formik.getFieldProps('otp')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            { 'is-invalid': formik.touched.otp && formik.errors.otp },
                            {
                                'is-valid': formik.touched.otp && !formik.errors.otp,
                            }
                        )}
                        type='number'
                        name='otp'
                        autoComplete='off'
                    />
                    {formik.touched.otp && formik.errors.otp && (
                        <div className='fv-plugins-message-container'>
                            <span className='error'>{formik.errors.otp}</span>
                        </div>
                    )}
                </div>
                {/* end::Form group */}


                {/* begin::Action */}
                <div className='text-center'>
                    <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                    // disabled={formik.isSubmitting || !formik.isValid}
                    >
                        {!loading && <span className='indicator-label'>Verify OTP</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Verifying your otp...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>

                </div>

                {/* end::Action */}
            </form>
        </div>
    )
}
