/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { loginwithOTP } from '../redux/AuthCRUD'
import { toast } from 'react-toastify'

const loginSchema = Yup.object().shape({
  email_mobile: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Mobile Number is required'),
})

const initialValues = {
  email_mobile: '',
  country_code: '+91',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
export function OtpLogin() {
  const [loading, setLoading] = useState(false)
  let history = useHistory();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        console.log(values);
        loginwithOTP(values).then((responce) => {
            if(responce.status === 200){
                const { user_id } = responce.data.data;
                console.log(user_id)
                if(user_id){
                    history.push('/auth/otp-verify',{
                        user_id
                    })
                    toast.success(responce.data.replyMsg)
                }
            }
            else{
                setLoading(false)
                setSubmitting(false)
                setStatus(responce.data.replyMsg)
            }
         })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)
            setStatus('check Login details and internet Connection ..!')
          })
      }, 1000)
    },
  })

  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign in with OTP</h1>

      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Mobile Number</label>
        <input
          placeholder='Enter your Mobile Number'
          {...formik.getFieldProps('email_mobile')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email_mobile && formik.errors.email_mobile },
            {
              'is-valid': formik.touched.email_mobile && !formik.errors.email_mobile,
            }
          )}
          type='text'
          name='email_mobile'
          autoComplete='off'
        />
        {formik.touched.email_mobile && formik.errors.email_mobile && (
          <div className='fv-plugins-message-container'>
            <span className='error'>{formik.errors.email_mobile}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

    
      {/* end::Form group */}
      <div className='d-flex justify-content-between mt-n5 mb-5'>
      <Link
          to='/auth/registration'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
            New user? Register here
            </Link>
            <Link
          to='/auth/memberlogin'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
            Member Login
            </Link>
      </div>
     
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          // disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Send OTP</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

      </div>
            {/* seperator */}
            <div className="separator separator-content my-5"></div>
      {/* login otp link  */}
        <div className='text-center'>
        <Link
          to='/auth/login'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              Back to Login with Password
            </Link>
      </div>
      {/* end::Action */}
    </form>
    </div>
  )
}
