import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const branches = [
  {id: 1, name: 'Manage'},
  {id: 2, name: 'Khokran'},
  {id: 3, name: 'Vasna'},
  {id: 4, name: 'Navanarangpura'},
]

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetBranches: '[Set Branches] Action',
  SelectBranch: '[Select Branch] Action',
  GetModuleDetails: '[Get Module Details] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  branches: [], // Initialize with an array of branches
  selectedBranch: undefined, // Initialize with a default branch name
  accessModuleList:[],
}

export interface IAuthState {
  user?: any
  accessToken?: string
  branches?: any
  selectedBranch?: any
  accessModuleList?: any
}

export const reducer = persistReducer(
  {
    storage,
    key: 'v100-demo1-auth',
    whitelist: ['user', 'accessToken', 'branches', 'selectedBranch'],
  },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetBranches: {
        const branches = action.payload?.branches
        return {...state, branches}
      }

      case actionTypes.SelectBranch: {
        const selectedBranch = action.payload?.selectedBranch
        return {...state, selectedBranch}
      }

      case actionTypes.GetModuleDetails: {
        const accessModuleList = action.payload
        return {...state, accessModuleList}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: any) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: any) => ({type: actionTypes.SetUser, payload: {user}}),
  setBranches: (branches: any) => ({type: actionTypes.SetBranches, payload: {branches}}),
  selectBranch: (selectedBranch: string) => ({
    type: actionTypes.SelectBranch,
    payload: {selectedBranch},
  }),
  getModuleDetails: (moduledetails: any) => ({
    type: actionTypes.GetModuleDetails,
    payload: moduledetails,
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken()
    //  yield put(actions.getModuleDetails(user?.ActivePlanModules))
    yield put(actions.fulfillUser(user))
  })
}
