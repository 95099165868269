import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import * as auth from "./redux/AuthRedux";

export function Logout() {
  const data: any = localStorage.getItem("activeUserType");
  const user = JSON.parse(data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(auth.actions.logout());
    document.location.reload();
    localStorage.removeItem("loginDetails");
    localStorage.removeItem("activeUserType");
    localStorage.removeItem("activePlanModules");
    localStorage.removeItem("MyProfile");
    localStorage.removeItem("persist:v100-demo1-auth");
    localStorage.removeItem("persist:v100-demo1-designation");
    localStorage.removeItem("ActiveBranchList");
    // localStorage.removeItem("setLanguage");
    localStorage.removeItem("permissionData");
    localStorage.removeItem("CountryCurrencycode");
    localStorage.removeItem("ActionplanUUID");
    localStorage.removeItem("timeZoneList");
    localStorage.removeItem("persist:v100-language-auth");
    if (user === "member") {
      localStorage.removeItem("ActiveBranch");
      localStorage.clear();
    }
  }, [dispatch]);
  return (
    <Switch>
      <Redirect to="/auth/login" />
    </Switch>
  );
}
