import React, { useEffect, useRef } from "react";

const Pagination = ({ page, setPage, totalPages }: any) => {
  const pagesToShow = 5;
  const startPage = Math.max(1, page - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
  const paginationRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (paginationRef.current) {
  //       paginationRef.current.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }, 0);

  //   return () => clearTimeout(timeout);
  // }, [page]);

  const selectPageHandler = (selectedPage: number) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPages &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  return (
    <div ref={paginationRef} className="p-5">
      <div className="row">
        <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
        <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
          <div id="kt_table_users_paginate">
            <ul className="pagination">
              <li className="page-item start">
                <a
                  className={`btn btn-sm btn-primary fs-6 ${
                    page === 1 ? "d-none" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => selectPageHandler(1)}
                >
                  Start
                </a>
              </li>
              <li className="page-item previous">
                <a
                  className={`btn btn-sm btn-primary ${
                    page === 1 ? "d-none" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => selectPageHandler(page - 1)}
                >
                  <i className="bi bi-arrow-left-short  fs-1 fw-bolder"></i>
                </a>
              </li>
              {[...Array(endPage - startPage + 1)].map((_, i) => {
                const pageNumber = startPage + i;
                return (
                  <li
                    className={
                      page === pageNumber
                        ? "page-item active fs-4"
                        : "page-item"
                    }
                    onClick={() => selectPageHandler(pageNumber)}
                    key={pageNumber}
                  >
                    <a className="page-link" style={{ cursor: "pointer" }}>
                      {pageNumber}
                    </a>
                  </li>
                );
              })}
              <li className="page-item end">
                <a
                  className={`page-link fs-6  ${
                    page === totalPages ? "d-none" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => selectPageHandler(totalPages)}
                >
                  {`. . . ${totalPages}`}
                </a>
              </li>
              <li
                className={`page-item next ${
                  page === totalPages ? "d-none" : ""
                }`}
              >
                <a
                  className="btn btn-sm btn-primary "
                  style={{ cursor: "pointer" }}
                  onClick={() => selectPageHandler(page + 1)}
                >
                  <i className="bi bi-arrow-right-short fs-1 fw-bolder"></i>
                </a>
              </li>

              <li className="page-item end">
                <a
                  className={`btn btn-sm btn-primary fs-6  ${
                    page === totalPages ? "d-none" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => selectPageHandler(totalPages)}
                >
                  End
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
