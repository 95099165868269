import React from "react";
import { Route, Switch } from "react-router-dom";
// import Company from "./Company";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import WhatsappList from "./componants/EmailList";
import EmailList from "./componants/EmailList";
import { useIntl } from "react-intl";
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "Email",
    path: "/template/email",
    isSeparator: false,
    isActive: false,
  },
];
const EmailPage: React.FC = () => {
  const intl = useIntl();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  return (
    <>
      <Switch>
        <Route path="/template/email">
          <PageTitle>{intl.formatMessage({ id: "COMMON.EMAIL" })}</PageTitle>
          <EmailList />
        </Route>
      </Switch>
    </>
  );
};

export default EmailPage;
