import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";
const LANG_URL = process.env.REACT_APP_I18NLANG_API_KEY;
export const GET_ALL_LANGUAGE = `${API_URL}/v1/languagelist`;
export const GET_LANGUAGE_FILE_BRANCHES = `${LANG_URL}/translation`;
export const GET_ALL_MODULES = `${API_URL}/v1/activemodules/list`;

//create branch
export function GetAllLanguages() {
  return axios.get(GET_ALL_LANGUAGE).catch((error) => {
    return error.response;
  });
}
export function getallmodules() {
  return axios.get(GET_ALL_MODULES).catch((error) => {
    return error.response;
  });
}

//getting all branches

export function GetLanguageFile(languageSlug: string = "en") {
  return axios
    .get(`${GET_LANGUAGE_FILE_BRANCHES}/${languageSlug}/language.json`)
    .catch((error) => {
      return error.response;
    });
}

// export function GetLanguageFile(languageSlug: string) {
//   if (languageSlug !== null) {
//     return axios.get(GET_LANGUAGE_FILE_BRANCHES + '/' + languageSlug+'/language.json').catch((error) => {
//       return error.response
//     })
//   } else {
//     return axios.get(GET_LANGUAGE_FILE_BRANCHES + '/en/language.json').catch((error) => {
//       return error.response
//     })
//   }
// }
