import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_REMINDER_LIST = `${API_URL}/v1/fitnesscenter/activity/list`;
export const ADD_REMINDER = `${API_URL}/v1/fitnesscenter/activity/add`;
export const UPDATE_REMINDER = `${API_URL}/v1/activity/update`;
export const UPDDATE_STATUS = `${API_URL}/v1/activity/updatestatus`;
export const DELETE_STATUS = `${API_URL}/v1/activity/delete`;
export const DETAILS_REMINDER = `${API_URL}/v1/fitnesscenter/activity/details`;

export function GetReminderList(body: any) {
  return axios.post(GET_REMINDER_LIST, body).catch((error) => {
    return error.response;
  });
}
export function AddReminder(body: any) {
  return axios.post(ADD_REMINDER, body).catch((error) => {
    return error.response;
  });
}
export function UpdateReminder(body: any) {
  return axios.put(UPDATE_REMINDER, body).catch((error) => {
    return error.response;
  });
}
export function UpdateStatus(body: any) {
  return axios.patch(UPDDATE_STATUS, body).catch((error) => {
    return error.response;
  });
}
export function deleteReminder(body: any) {
  return axios.patch(DELETE_STATUS, body).catch((error) => {
    return error.response;
  });
}
export function getDetailsOfReminder(body: any, uuid: any) {
  return axios.get(`${DETAILS_REMINDER}/${body}/${uuid}`).catch((error) => {
    return error.response;
  });
}
