import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

// Updated ActionTypes Object
export const actionTypes = {
  GetSMS: "[GetSMS] Action",
};

// Updated Initial State
const initialAuthState: IPackagePeriodState = {
  SMS: undefined,
};

// Updated Department State Interface
export interface IPackagePeriodState {
  SMS?: any;
}

// Updated Reducer Function
export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["SMS"] },
  (
    state: IPackagePeriodState = initialAuthState,
    action: ActionWithPayload<IPackagePeriodState>
  ) => {
    switch (action.type) {
      case actionTypes.GetSMS: {
        const SMS = action.payload?.SMS;
        return { ...state, SMS };
      }
      default:
        return state;
    }
  }
);

// Updated Actions Object
export const actions = {
  GetSMS: (SMS: any) => ({
    type: actionTypes.GetSMS,
    payload: { SMS },
  }),
};
