import { Action } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


export interface ActionWithPayload<T> extends Action {
    payload?: T
  }
// Updated ActionTypes Object for Designations
export const actionTypes = {
  GetDesignations: '[GetDesignations] Action',
};

// Updated Initial State for Designations
const initialDesignationState: IDesignationState = {
  designations: undefined,
};

// Updated Designation State Interface
export interface IDesignationState {
  designations?: any;
}

// Updated Reducer Function for Designations
export const  reducer = persistReducer(
  { storage, key: 'v100-demo1-designation', blacklist: ['designations'] },
  (state: IDesignationState = initialDesignationState, action: ActionWithPayload<IDesignationState>) => {
    switch (action.type) {
      case actionTypes.GetDesignations: {
        const designations = action.payload?.designations;
        return { ...state, designations };
      }
      default:
        return state;
    }
  }
);

// Updated Actions Object for Designations
export const actions = {
  GetDesignations: (designations: string) => ({ type: actionTypes.GetDesignations, payload: { designations } }),
};
