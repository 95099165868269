/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import {
  GetActiveBranches,
  getCountryCodeList,
  getTimeZoneList,
  login,
  loginMember,
} from "../redux/AuthCRUD";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useIntl } from "react-intl";

const loginSchema = Yup.object().shape({
  email_mobile: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email_mobile: "",
  password: "",
  country_code: "",
  login_type: "website",
  device_token: "wddwedq",
  public_ip_address: "127.0.1",
  broswer: "chrome",
  browser_version: "54",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
export function MemberLogin() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        // console.log(values);
        loginMember(values)
          .then((responce) => {
            // console.log(responce, "login screen response");
            if (responce.status == 200) {
              const {
                role_id,
                access_token,
                plans,
                vFirstName,
                vLastName,
                vEmail,
                ActivePlanModules,
                activeBranchData,
                activeplan_UUID,
                userType,
              } = responce.data.data;
              localStorage.setItem(
                "activeUser",
                JSON.stringify(responce.data.data)
              );
              localStorage.setItem(
                "ActiveBranch",
                JSON.stringify(activeBranchData)
              );
              localStorage.setItem(
                "activeBranchData",
                JSON.stringify(activeBranchData)
              );
              localStorage.setItem("activeUserType", JSON.stringify(userType));
              localStorage.setItem(
                "activePlanModules",
                JSON.stringify(ActivePlanModules)
              );
              localStorage.setItem(
                "ActionplanUUID",
                JSON.stringify(activeplan_UUID)
              );

              getCountryCodeList().then((response) => {
                console.log("country code response,,,,,,", response);
                localStorage.setItem(
                  "CountryCurrencycode",
                  JSON.stringify(response.data.data)
                );
              });
              getTimeZoneList().then((response) => {
                console.log("timeZoneList response,,,,,,", response);
                localStorage.setItem(
                  "timeZoneList",
                  JSON.stringify(response.data.data)
                );
              });

              GetActiveBranches().then((response) => {
                console.log("Activebrnach  List response,,,,,,", response);
                localStorage.setItem(
                  "ActiveBranchList",
                  JSON.stringify(response.data.data)
                );
              });

              //if(role_id !== 2 && role_id !== 3) {
              setLoading(false);
              dispatch(auth.actions.login(access_token));
              toast.success("Login SuccessFully");
              dispatch(auth.actions.getModuleDetails(ActivePlanModules));
            } else {
              // console.log(responce, "responce");
              setStatus(responce.data.replyMsg);
              setLoading(false);
            }
          })
          .catch((err) => {
            // console.log(err);
            setLoading(false);
            setSubmitting(false);
            setStatus("check Login details and internet Connection ..!");
          });
      }, 1000);
    },
  });

  return (
    <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Heading */}
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "MODULE.AUTH.HEADING2" })}
          </h1>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="required form-label fs-6 fw-bolder text-dark">
            {intl.formatMessage({ id: "MODULE.AUTH.EMAIL" })}
          </label>
          <input
            placeholder="Email"
            {...formik.getFieldProps("email_mobile")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.email_mobile && formik.errors.email_mobile,
              },
              {
                "is-valid":
                  formik.touched.email_mobile && !formik.errors.email_mobile,
              }
            )}
            type="email"
            name="email_mobile"
            autoComplete="off"
          />
          {formik.touched.email_mobile && formik.errors.email_mobile && (
            <div className="fv-plugins-message-container text-danger">
              <span className="error">{formik.errors.email_mobile}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <div className="d-flex justify-content-between mt-n5">
            <div className="d-flex flex-stack mb-2">
              {/* begin::Label */}
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">
                {intl.formatMessage({ id: "MODULE.AUTH.PASSWORD" })}
              </label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to="/auth/forgot-member-password"
                className="link-primary fs-6 fw-bolder"
                style={{ marginLeft: "5px" }}
              >
                {intl.formatMessage({ id: "MODULE.AUTH.FORGOTPASSWORD" })}
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <div className="position-relative">
            <input
              type={`${passShow ? "text" : "password"}`}
              autoComplete="off"
              placeholder="Password"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <span
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
              style={{ cursor: "pointer" }}
              onClick={() => setPassShow(!passShow)}
            >
              {passShow ? (
                <FaEyeSlash className="fs-4 text-primary me-8" />
              ) : (
                <FaEye className="fs-4 text-muted me-8" />
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span className="error">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className="d-flex justify-content-end mt-n5 mb-5">
          <Link
            to="/auth/login"
            className="link-primary fs-6 fw-bolder"
            style={{ marginLeft: "5px" }}
          >
            {intl.formatMessage({ id: "MODULE.AUTH.NOTAMEMBER" })}
          </Link>
        </div>
        {/* begin::Action */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "MODULE.AUTH.SIGNIN" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "COMMON.PLEASEWAIT" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </div>
  );
}
