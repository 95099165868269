import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./loading.css";

const Loading = () => {
  const loading = useSelector((state) => state?.loader?.loading); // Get loading state from Redux

  useEffect(() => {
    if (loading) {
      document.getElementById("content1").classList.add("blurred-content");
    } else {
      document.getElementById("content1").classList.remove("blurred-content");
    }

    return () => {
      document.getElementById("content1").classList.remove("blurred-content");
    };
  }, [loading]);

  if (!loading) return null;

  return (
    <div className="spinnerround">
      <div className="spinner"></div>
    </div>
  );
};

export default Loading;
