export  const CountryCode = [
    { country: 'Afghanistan', code: '+93', length: 9 },
    { country: 'Albania', code: '+355', length: 9 },
    { country: 'Algeria', code: '+213', length: 9 },
    { country: 'Andorra', code: '+376', length: 9 },
    { country: 'Angola', code: '+244', length: 9 },
    { country: 'Antigua and Barbuda', code: '+1-268', length: 7 },
    { country: 'Argentina', code: '+54', length: 10 },
    { country: 'Armenia', code: '+374', length: 8 },
    { country: 'Australia', code: '+61', length: 9 },
    { country: 'Austria', code: '+43', length: 10 },
    { country: 'Azerbaijan', code: '+994', length: 9 },
    { country: 'Bahamas', code: '+1-242', length: 7 },
    { country: 'Bahrain', code: '+973', length: 8 },
    { country: 'Bangladesh', code: '+880', length: 10 },
    { country: 'Barbados', code: '+1-246', length: 7 },
    { country: 'Belarus', code: '+375', length: 9 },
    { country: 'Belgium', code: '+32', length: 9 },
    { country: 'Belize', code: '+501', length: 7 },
    { country: 'Benin', code: '+229', length: 8 },
    { country: 'Bhutan', code: '+975', length: 8 },
    { country: 'Bolivia', code: '+591', length: 8 },
    { country: 'Bosnia and Herzegovina', code: '+387', length: 8 },
    { country: 'Botswana', code: '+267', length: 8 },
    { country: 'Brazil', code: '+55', length: 11 },
    { country: 'Brunei', code: '+673', length: 7 },
    { country: 'Bulgaria', code: '+359', length: 9 },
    { country: 'Burkina Faso', code: '+226', length: 8 },
    { country: 'Burundi', code: '+257', length: 8 },
    { country: 'Cambodia', code: '+855', length: 8 },
    { country: 'Cameroon', code: '+237', length: 8 },
    { country: 'Canada', code: '+1', length: 10 },
    { country: 'Cape Verde', code: '+238', length: 7 },
    { country: 'Central African Republic', code: '+236', length: 8 },
    { country: 'Chad', code: '+235', length: 8 },
    { country: 'Chile', code: '+56', length: 9 },
    { country: 'China', code: '+86', length: 11 },
    { country: 'Colombia', code: '+57', length: 10 },
    { country: 'Comoros', code: '+269', length: 7 },
    { country: 'Congo', code: '+242', length: 8 },
    { country: 'Costa Rica', code: '+506', length: 8 },
    { country: 'Croatia', code: '+385', length: 9 },
    { country: 'Cuba', code: '+53', length: 9 },
    { country: 'Cyprus', code: '+357', length: 8 },
    { country: 'Czech Republic', code: '+420', length: 9 },
    { country: 'Democratic Republic of the Congo', code: '+243', length: 9 },
    { country: 'Denmark', code: '+45', length: 8 },
    { country: 'Djibouti', code: '+253', length: 8 },
    { country: 'Dominica', code: '+1-767', length: 7 },
    { country: 'Dominican Republic', code: '+1-809, +1-829, +1-849', length: 10 },
    { country: 'Ecuador', code: '+593', length: 10 },
    { country: 'Egypt', code: '+20', length: 10 },
    { country: 'El Salvador', code: '+503', length: 8 },
    { country: 'Equatorial Guinea', code: '+240', length: 8 },
    { country: 'Eritrea', code: '+291', length: 7 },
    { country: 'Estonia', code: '+372', length: 8 },
    { country: 'Eswatini', code: '+268', length: 8 },
    { country: 'Ethiopia', code: '+251', length: 9 },
    { country: 'Fiji', code: '+679', length: 8 },
    { country: 'Finland', code: '+358', length: 10 },
    { country: 'France', code: '+33', length: 9 },
    { country: 'Gabon', code: '+241', length: 8 },
    { country: 'Gambia', code: '+220', length: 7 },
    { country: 'Georgia', code: '+995', length: 9 },
    { country: 'Germany', code: '+49', length: 11 },
    { country: 'Ghana', code: '+233', length: 9 },
    { country: 'Greece', code: '+30', length: 10 },
    { country: 'Grenada', code: '+1-473', length: 7 },
    { country: 'Guatemala', code: '+502', length: 8 },
    { country: 'Guinea', code: '+224', length: 8 },
    { country: 'Guinea-Bissau', code: '+245', length: 7 },
    { country: 'Guyana', code: '+592', length: 8 },
    { country: 'Guyana', code: '+592', length: 8 },
    { country: 'Haiti', code: '+509', length: 8 },
    { country: 'Honduras', code: '+504', length: 8 },
    { country: 'Hungary', code: '+36', length: 9 },
    { country: 'Iceland', code: '+354', length: 7 },
    { country: 'India', code: '+91', length: 10 },
    { country: 'Indonesia', code: '+62', length: 11 },
    { country: 'Iran', code: '+98', length: 10 },
    { country: 'Iraq', code: '+964', length: 10 },
    { country: 'Ireland', code: '+353', length: 9 },
    { country: 'Israel', code: '+972', length: 9 },
    { country: 'Italy', code: '+39', length: 10 },
    { country: 'Jamaica', code: '+1-876', length: 7 },
    { country: 'Japan', code: '+81', length: 10 },
    { country: 'Jordan', code: '+962', length: 9 },
    { country: 'Kazakhstan', code: '+7', length: 10 },
    { country: 'Kenya', code: '+254', length: 9 },
    { country: 'Kiribati', code: '+686', length: 5 },
    { country: 'Korea, North', code: '+850', length: 10 },
    { country: 'Korea, South', code: '+82', length: 10 },
    { country: 'Kuwait', code: '+965', length: 8 },
    { country: 'Kyrgyzstan', code: '+996', length: 9 },
    { country: 'Laos', code: '+856', length: 8 },
    { country: 'Latvia', code: '+371', length: 8 },
    { country: 'Lebanon', code: '+961', length: 8 },
    { country: 'Lesotho', code: '+266', length: 8 },
    { country: 'Liberia', code: '+231', length: 7 },
    { country: 'Libya', code: '+218', length: 9 },
    { country: 'Liechtenstein', code: '+423', length: 8 },
    { country: 'Lithuania', code: '+370', length: 8 },
    { country: 'Luxembourg', code: '+352', length: 9 },
    { country: 'Madagascar', code: '+261', length: 9 },
    { country: 'Malawi', code: '+265', length: 9 },
    { country: 'Malaysia', code: '+60', length: 9 },
    { country: 'Maldives', code: '+960', length: 7 },
    { country: 'Mali', code: '+223', length: 8 },
    { country: 'Malta', code: '+356', length: 8 },
    { country: 'Marshall Islands', code: '+692', length: 7 },
    { country: 'Mauritania', code: '+222', length: 8 },
    { country: 'Mauritius', code: '+230', length: 8 },
    { country: 'Mexico', code: '+52', length: 10 },
    { country: 'Micronesia', code: '+691', length: 7 },
    { country: 'Moldova', code: '+373', length: 8 },
    { country: 'Monaco', code: '+377', length: 8 },
    { country: 'Mongolia', code: '+976', length: 8 },
    { country: 'Montenegro', code: '+382', length: 9 },
    { country: 'Morocco', code: '+212', length: 9 },
    { country: 'Mozambique', code: '+258', length: 9 },
    { country: 'Myanmar', code: '+95', length: 9 },
    { country: 'Namibia', code: '+264', length: 8 },
    { country: 'Nauru', code: '+674', length: 7 },
    { country: 'Nepal', code: '+977', length: 10 },
    { country: 'Netherlands', code: '+31', length: 9 },
    { country: 'New Zealand', code: '+64', length: 9 },
    { country: 'Nicaragua', code: '+505', length: 8 },
    { country: 'Niger', code: '+227', length: 8 },
    { country: 'Nigeria', code: '+234', length: 10 },
    { country: 'North Macedonia', code: '+389', length: 8 },
    { country: 'Norway', code: '+47', length: 8 },
    { country: 'Oman', code: '+968', length: 8 },
    { country: 'Pakistan', code: '+92', length: 10 },
    { country: 'Palau', code: '+680', length: 7 },
    { country: 'Panama', code: '+507', length: 8 },
    { country: 'Papua New Guinea', code: '+675', length: 7 },
    { country: 'Paraguay', code: '+595', length: 9 },
    { country: 'Peru', code: '+51', length: 9 },
    { country: 'Panama', code: '+507', length: 8 },
    { country: 'Papua New Guinea', code: '+675', length: 7 },
    { country: 'Paraguay', code: '+595', length: 9 },
    { country: 'Peru', code: '+51', length: 9 },
    { country: 'Philippines', code: '+63', length: 10 },
    { country: 'Poland', code: '+48', length: 9 },
    { country: 'Portugal', code: '+351', length: 9 },
    { country: 'Qatar', code: '+974', length: 8 },
    { country: 'Romania', code: '+40', length: 9 },
    { country: 'Russia', code: '+7', length: 10 },
    { country: 'Rwanda', code: '+250', length: 9 },
    { country: 'Saint Kitts and Nevis', code: '+1-869', length: 7 },
    { country: 'Saint Lucia', code: '+1-758', length: 7 },
    { country: 'Saint Vincent and the Grenadines', code: '+1-784', length: 7 },
    { country: 'Samoa', code: '+685', length: 6 },
    { country: 'San Marino', code: '+378', length: 7 },
    { country: 'Sao Tome and Principe', code: '+239', length: 7 },
    { country: 'Saudi Arabia', code: '+966', length: 9 },
    { country: 'Senegal', code: '+221', length: 9 },
    { country: 'Serbia', code: '+381', length: 9 },
    { country: 'Seychelles', code: '+248', length: 7 },
    { country: 'Sierra Leone', code: '+232', length: 8 },
    { country: 'Singapore', code: '+65', length: 8 },
    { country: 'Slovakia', code: '+421', length: 9 },
    { country: 'Slovenia', code: '+386', length: 8 },
    { country: 'Solomon Islands', code: '+677', length: 7 },
    { country: 'Somalia', code: '+252', length: 8 },
    { country: 'South Africa', code: '+27', length: 9 },
    { country: 'South Sudan', code: '+211', length: 9 },
    { country: 'Spain', code: '+34', length: 9 },
    { country: 'Sri Lanka', code: '+94', length: 10 },
    { country: 'Sudan', code: '+249', length: 9 },
    { country: 'Suriname', code: '+597', length: 7 },
    { country: 'Sweden', code: '+46', length: 10 },
    { country: 'Switzerland', code: '+41', length: 9 },
    { country: 'Syria', code: '+963', length: 9 },
    { country: 'Taiwan', code: '+886', length: 9 },
    { country: 'Tajikistan', code: '+992', length: 9 },
    { country: 'Tanzania', code: '+255', length: 9 },
    { country: 'Thailand', code: '+66', length: 9 },
    { country: 'Timor-Leste', code: '+670', length: 7 },
    { country: 'Togo', code: '+228', length: 8 },
    { country: 'Tonga', code: '+676', length: 5 },
    { country: 'Trinidad and Tobago', code: '+1-868', length: 7 },
    { country: 'Tunisia', code: '+216', length: 8 },
    { country: 'Turkey', code: '+90', length: 10 },
    { country: 'Turkmenistan', code: '+993', length: 8 },
    { country: 'Tuvalu', code: '+688', length: 5 },
    { country: 'Uganda', code: '+256', length: 9 },
    { country: 'Ukraine', code: '+380', length: 9 },
    { country: 'United Arab Emirates', code: '+971', length: 9 },
    { country: 'United Kingdom', code: '+44', length: 10 },
    { country: 'United States', code: '+1', length: 10 },
    { country: 'Uruguay', code: '+598', length: 8 },
    { country: 'Uzbekistan', code: '+998', length: 9 },
    { country: 'Vanuatu', code: '+678', length: 7 },
    { country: 'Vatican City', code: '+379', length: 6 },
    { country: 'Venezuela', code: '+58', length: 10 },
    { country: 'Vietnam', code: '+84', length: 10 },
    { country: 'Yemen', code: '+967', length: 9 },
    { country: 'Zambia', code: '+260', length: 9 },
    { country: 'Zimbabwe', code: '+263', length: 9 }
    // ... continue adding more countries
  ];
  

  