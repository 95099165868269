import React from "react";
import { Route, Switch } from "react-router-dom";
// import Company from "./Company";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import Setting from "./Componants/Setting";
import { useIntl } from "react-intl";
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "Setting",
    path: "/setting/details",
    isSeparator: false,
    isActive: false,
  },
];
const SettingPage: React.FC = () => {
  const intl = useIntl();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  return (
    <>
      <Switch>
        <Route path="/setting/details/:companyUUID">
          <PageTitle>{intl.formatMessage({ id: "COMMON.SETTING" })}</PageTitle>
          <Setting user={user} />
        </Route>
      </Switch>
    </>
  );
};

export default SettingPage;
