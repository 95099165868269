import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetReminders: "[GetReminders] Action",
};

const initialAuthState: ICenterState = {
  reminders: undefined,
};

export interface ICenterState {
  reminders?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["reminders"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetReminders: {
        const reminders = action.payload?.reminders;
        return { ...state, reminders };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetReminders: (reminders: string) => ({
    type: actionTypes.GetReminders,
    payload: { reminders },
  }),
};
