import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetActivity: "[GetActivity] Action",
};

const initialAuthState: ICenterState = {
  activities: undefined,
};

export interface ICenterState {
  activities?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["activities"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetActivity: {
        const activities = action.payload?.activities;
        return { ...state, activities };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetActivity: (activities: string) => ({
    type: actionTypes.GetActivity,
    payload: { activities },
  }),
};
