/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage } from "../modules/auth";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { RootState } from "../../setup";
import { InvoicePage } from "../modules/Invoice";

const Routes: FC = () => {
  // have to add logic for paid un-paid here
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  return (
    <Switch>
      <Route path="/view-invoice/:id" component={InvoicePage} />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}
    </Switch>
  );
};

export { Routes };
