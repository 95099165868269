import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

// Updated ActionTypes Object
export const actionTypes = {
  GetExpenses: "[GetExpenses] Action",
};

// Updated Initial State
const initialAuthState: IPackagePeriodState = {
  Expenses: undefined,
};

// Updated Department State Interface
export interface IPackagePeriodState {
  Expenses?: any;
}

// Updated Reducer Function
export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Expenses"] },
  (
    state: IPackagePeriodState = initialAuthState,
    action: ActionWithPayload<IPackagePeriodState>
  ) => {
    switch (action.type) {
      case actionTypes.GetExpenses: {
        const Expenses = action.payload?.Expenses;
        return { ...state, Expenses };
      }
      default:
        return state;
    }
  }
);

// Updated Actions Object
export const actions = {
  GetExpenses: (Expenses: any) => ({
    type: actionTypes.GetExpenses,
    payload: { Expenses },
  }),
};
