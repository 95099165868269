import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import InvoiceList from "../InvoiceList";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetAttendences: "[GetAttendences] Action",
};

const initialAuthState: ICenterState = {
  biometricEmployee: undefined,
};

export interface ICenterState {
  biometricEmployee?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["biometricEmployee"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetAttendences: {
        const biometricEmployee = action.payload?.biometricEmployee;
        return { ...state, biometricEmployee };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetAttendences: (biometricEmployee: string) => ({
    type: actionTypes.GetAttendences,
    payload: { biometricEmployee },
  }),
};
