import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}


// Updated ActionTypes Object
export const actionTypes = {
  GetBatches: '[GetBatches] Action',
  }
  
  // Updated Initial State
  const initialAuthState: IBatchState = {
    batches: undefined,
  }
  
  // Updated Department State Interface
  export interface IBatchState {
    batches?: any
  }
  
  // Updated Reducer Function
  export const reducer = persistReducer(
    { storage, key: 'v100-demo1-auth', blacklist: ['batches'] },
    (state: IBatchState = initialAuthState, action: ActionWithPayload<IBatchState>) => {
      switch (action.type) {
        case actionTypes.GetBatches: {
          const batches = action.payload?.batches
          return { ...state, batches }
        }
        default:
          return state
      }
    }
  )
  
  // Updated Actions Object
  export const actions = {
    GetBatches: (batches:any) => ({ type: actionTypes.GetBatches, payload: { batches } }),
  }
  