import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}


// Updated ActionTypes Object
export const actionTypes = {
  GetPackagePeriods: '[GetPackagePeriods] Action',
  }
  
  // Updated Initial State
  const initialAuthState: IPackagePeriodState = {
    packageperiods: undefined,
  }
  
  // Updated Department State Interface
  export interface IPackagePeriodState {
    packageperiods?: any
  }
  
  // Updated Reducer Function
  export const reducer = persistReducer(
    { storage, key: 'v100-demo1-auth', blacklist: ['packagegroups'] },
    (state: IPackagePeriodState = initialAuthState, action: ActionWithPayload<IPackagePeriodState>) => {
      switch (action.type) {
        case actionTypes.GetPackagePeriods: {
          const packageperiods = action.payload?.packageperiods
          return { ...state, packageperiods }
        }
        default:
          return state
      }
    }
  )
  
  // Updated Actions Object
  export const actions = {
    GetPackagePeriods: (packageperiods:any) => ({ type: actionTypes.GetPackagePeriods, payload: { packageperiods } }),
  }
  