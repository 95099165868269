import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import InvoiceList from "../InvoiceList";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetEmployeeAttendences: "[GetEmployeeAttendences] Action",
};

const initialAuthState: ICenterState = {
  Employeattendences: undefined,
};

export interface ICenterState {
  Employeattendences?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Employeattendences"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetEmployeeAttendences: {
        const Employeattendences = action.payload?.Employeattendences;
        return { ...state, Employeattendences };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetEmployeeAttendences: (Employeattendences: string) => ({
    type: actionTypes.GetEmployeeAttendences,
    payload: { Employeattendences },
  }),
};
