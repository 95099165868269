import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import isEqual from "lodash/isEqual";
// import Select from "react-select";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
// import {
//   CreateExpenseCategory,
//   UpdateExpenseCategory,
// } from "../../redux/ExpenseCategoryCRUD";
import { useSelector } from "react-redux";
import { UpdateExpenseCategory } from "../../redux/SMSCRUD";

const EditSms = ({
  show,
  setShow,
  getExpenseCategory, // Replace with your function to fetch departments
  UpdateUser,
  setUpdateUser,
  isUpdate,
  setisUpdate,
  error,
  setError,
}: any) => {
  //   const branches = useSelector((state: any) => state?.auth?.branches);
  const intl = useIntl();

  let activeBranch: any = localStorage.getItem("ActiveBranch");
  const selectedActiveBranch = JSON.parse(activeBranch);

  const createValue = {
    branchID: selectedActiveBranch.iBranchID, // Change the default branch ID as needed
    SMSTemplateUUID: "",
    dltTemplateID: "",
    messageTYPE: "",
    message: "",
    status: "1",
  };
  const UpdateValue = {
    branchID: UpdateUser?.iBranchID,

    status: UpdateUser?.eStatus,
    SMSTemplateUUID: UpdateUser?.usersmsmessageTemplateUUID,
    dltTemplateID: UpdateUser?.dlt_template_id,
    messageTYPE: UpdateUser?.mesaageType,
    message: UpdateUser?.vMessage,
  };

  const Schema = Yup.object().shape({
    dltTemplateID: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.BRANCH.REQUIRED" })}`
    ),
    message: Yup.string()
      .required(`${intl.formatMessage({ id: "REQUIRE.MODULE.MESSAGE" })}`)
      .test(
        "is-not-empty",
        `${intl.formatMessage({ id: "REQUIRE.MODULE.MESSAGE" })}`,
        (value) => {
          // Check if value is a string and contains non-whitespace characters
          return typeof value === "string" && value.trim().length > 0;
        }
      ),
    // Changed from departName to department_name
    // periodday: Yup.string().required(
    //   `${intl.formatMessage({ id: "MODULE.PACKAGEPERIOD.REQUIREDDAYS" })}`
    // ),
    status: Yup.string().required(
      `${intl.formatMessage({ id: "COMMON.STATUS.REQUIRED" })}`
    ),
  });

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: UpdateValue,
    validationSchema: Schema,
    onSubmit: async (values) => {
      // console.log("values", values);

      const response = await UpdateExpenseCategory(values);

      // console.log("response====", response);
      if (response.status === 200) {
        swal(response?.data?.replyMsg, { icon: "success" });
        formik.resetForm();
        setShow(false);
        getExpenseCategory(); // Fetch departments after updating
        setError();
      } else {
        // console.log("response.data.replyMsg====", response.data.replyMsg);
        setError(response.data.replyMsg);
      }
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        formik.resetForm();
        setisUpdate(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isUpdate
            ? `${intl.formatMessage({
                id: "MODULE.EXPENSECATEGORY.UPDATE",
              })}`
            : `${intl.formatMessage({
                id: "MODULE.EXPENSECATEGORY.ADD",
              })}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label htmlFor="dltTemplateID" className="form-label">
              {intl.formatMessage({ id: "MODULE.SMS.MESSAGE.TEMPID" })}
            </label>
            <input
              type="text"
              id="dltTemplateID"
              name="dltTemplateID"
              placeholder={intl.formatMessage({
                id: "MODULE.SMS.MESSAGE.TEMPID",
              })}
              className="form-control"
              onChange={formik.handleChange("dltTemplateID")}
              onBlur={formik.handleBlur("dltTemplateID")}
              value={formik.values.dltTemplateID}
            />
            {formik.errors.dltTemplateID ? (
              <div className="text-danger"> {formik.errors.dltTemplateID}</div>
            ) : (
              error && <div className="text-danger">{error}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="messageTYPE" className="form-label">
              {intl.formatMessage({ id: "MODULE.SMS.MESSAGE.TYPE" })}
            </label>
            <input
              type="text"
              id="mesaageType"
              name="messageTYPE"
              placeholder={intl.formatMessage({
                id: "MODULE.SMS.MESSAGE.TEMPID",
              })}
              className="form-control"
              value={formik.values.messageTYPE}
              disabled
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              {intl.formatMessage({ id: "MODULE.SMS.MESSAGE.MESSAGE" })}
            </label>
            <textarea
              id="message"
              name="message"
              rows={5}
              value={formik.values.message}
              className="form-control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.message && (
              <div className="text-danger"> {formik.errors.message}</div>
            )}

            {/* // ) : (
            //   error && <div className="text-danger">{error}</div>
            // )} */}
          </div>

          <div className="mb-3">
            <label htmlFor="status" className="form-label">
              {intl.formatMessage({ id: "COMMON.STATUS" })}
            </label>
            <select
              id="status"
              name="status"
              className="form-select"
              onChange={(event) => {
                formik.handleChange(event); // formik.handleChange will handle setting the field value
              }}
              onBlur={formik.handleBlur("status")}
              value={formik.values.status}
            >
              <option value="1">
                {intl.formatMessage({ id: "COMMON.ACTIVE" })}
              </option>
              <option value="0">
                {intl.formatMessage({ id: "COMMON.INACTIVE" })}
              </option>
              {/* <option value="2">
                {intl.formatMessage({ id: "COMMON.DELETE" })}
              </option> */}
            </select>

            {/*  {formik.touched.status && formik.errors.status && (
              <div className="text-danger">{formik.errors.status}</div>
            )} */}
          </div>

          {/*      <div className="text-danger">{error}</div>
           */}
          <button type="submit" className="btn btn-primary">
            {isUpdate
              ? `${intl.formatMessage({
                  id: "MODULE.EXPENSECATEGORY.UPDATEBUTTON",
                })}`
              : `${intl.formatMessage({
                  id: "MODULE.EXPENSECATEGORY.SAVEBUTTON",
                })}`}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditSms;
