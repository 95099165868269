import React from "react";
import { Route, Switch } from "react-router-dom";
// import Company from "./Company";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import SMSList from "./componants/SMSList";
import { useIntl } from "react-intl";
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "SMS",
    path: "/template/sms",
    isSeparator: false,
    isActive: false,
  },
];
const SMSPage: React.FC = () => {
  const intl = useIntl();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  return (
    <>
      <Switch>
        <Route path="/template/sms">
          <PageTitle>{intl.formatMessage({ id: "COMMON.SMS" })}</PageTitle>
          <SMSList />
        </Route>
      </Switch>
    </>
  );
};

export default SMSPage;
