const permission = (moduleName: any) => {
  // Get the permission data from local storage
  let permission: any = localStorage?.getItem("permissionData");

  // Check if the permission data is "undefined" or null and handle it accordingly
  if (permission === "undefined" || permission === null) {
    return []; // or any default value you prefer
  }

  // Parse the permission data
  let Access;
  try {
    Access = JSON?.parse(permission);
  } catch (error) {
    console.error("Failed to parse permission data:", error);
    return []; // or any default value you prefer
  }

  // If Access is not an array, return an empty object
  if (!Array.isArray(Access)) {
    return []; // or any default value you prefer
  }

  // Find the relevant permission for the given moduleName
  const result = Access?.find((item: any) => {
    return item.moduleSlug === moduleName;
  });

  // If no result is found, return an empty object or handle it as needed
  if (!result) {
    return []; // or any default value you prefer
  }

  // Return the action permission
  return result.actionPermission;
};

export default permission;
