import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

// Updated ActionTypes Object
export const actionTypes = {
  GetFollowUp: "[GetFollowUp] Action",
};

// Updated Initial State
const initialAuthState: IPackagePeriodState = {
  FollowUps: undefined,
};

// Updated Department State Interface
export interface IPackagePeriodState {
  FollowUps?: any;
}

// Updated Reducer Function
export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["FollowUps"] },
  (
    state: IPackagePeriodState = initialAuthState,
    action: ActionWithPayload<IPackagePeriodState>
  ) => {
    switch (action.type) {
      case actionTypes.GetFollowUp: {
        const FollowUps = action.payload?.FollowUps;
        return { ...state, FollowUps };
      }
      default:
        return state;
    }
  }
);

// Updated Actions Object
export const actions = {
  GetFollowUp: (FollowUps: any) => ({
    type: actionTypes.GetFollowUp,
    payload: { FollowUps },
  }),
};
