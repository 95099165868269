import { toAbsoluteUrl } from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img src={toAbsoluteUrl('/media/logo.png')} className="h-50px" alt='Start logo' style={{ mixBlendMode: "darken" }} />
      <span>Loading ...</span>
    </div>
  )
}
