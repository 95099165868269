import React from "react";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useIntl } from "react-intl";

const MemberMenu = () => {
  const intl = useIntl();
  return (
    <>
      <AsideMenuItemWithSub
        to={`/usermember/membership/list`}
        icon="/media/icons/duotune/icon/Memberships.svg"
        title={intl.formatMessage({ id: "COMMON.MYMEMBERSHIP" })}
        fontIcon="bi-app-indicator"
      >
        <AsideMenuItem
          to={`/usermember/membership/list`}
          title={intl.formatMessage({ id: "COMMON.VIEW" })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to={`/usermember/packagefreeze/list`}
        icon="/media/icons/duotune/icon/membership-freeze.svg"
        title={intl.formatMessage({ id: "COMMON.MYFREEZEPCG" })}
        fontIcon="bi-app-indicator"
      >
        <AsideMenuItem
          to={`/usermember/packagefreeze/list`}
          title={intl.formatMessage({ id: "COMMON.VIEW" })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItemWithSub
        to={`/usermember/enquiry/list`}
        icon="/media/icons/duotune/art/art002.svg"
        title={intl.formatMessage({ id: "COMMON.MYENQUIRY" })}
        fontIcon="bi-app-indicator"
      >
        <AsideMenuItem
          to={`/usermember/enquiry/list`}
          title={intl.formatMessage({ id: "COMMON.VIEW" })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      <AsideMenuItemWithSub
        to={`/usermember/myattandance/list`}
        icon="/media/icons/duotune/icon/Attendance.svg"
        title={intl.formatMessage({ id: "COMMON.MYATTANDANCE" })}
        fontIcon="bi-app-indicator"
      >
        <AsideMenuItem
          to={`/usermember/myattandance/list`}
          title={intl.formatMessage({ id: "COMMON.MEMBERLOG" })}
          hasBullet={true}
        />
        <AsideMenuItem
          to={`/usermember/myattandance/biometriclog/list`}
          title={intl.formatMessage({ id: "COMMON.BIOMETRICLOG" })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to={`/usermember/finance`}
        icon="/media/icons/duotune/icon/finance.svg"
        title={intl.formatMessage({ id: "COMMON.FINANCE" })}
        fontIcon="bi-app-indicator"
      >
        <AsideMenuItemWithSub
          to={`/usermember/finance/invoices/list`}
          icon="/media/icons/duotune/icon/finance.svg"
          title={intl.formatMessage({ id: "COMMON.INVOICE" })}
          fontIcon="bi-app-indicator"
        >
          <AsideMenuItem
            to={`/usermember/finance/invoices/list`}
            title={intl.formatMessage({ id: "COMMON.VIEW" })}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to={`/usermember/finance/remainingbalance/list`}
          icon="/media/icons/duotune/icon/remaining-balance.svg"
          title={intl.formatMessage({ id: "COMMON.REMAINGBALNCE" })}
          fontIcon="bi-app-indicator"
        >
          <AsideMenuItem
            to={`/usermember/finance/remainingbalance/list`}
            title={intl.formatMessage({ id: "COMMON.VIEW" })}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to={`/usermember/pos/list`}
        icon="/media/icons/duotune/icon/pos.svg"
        title={intl.formatMessage({ id: "COMMON.MYPOS" })}
        fontIcon="bi-app-indicator"
      >
        <AsideMenuItem
          to={`/usermember/pos/list`}
          title={intl.formatMessage({ id: "COMMON.VIEW" })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
    </>
  );
};

export default MemberMenu;
