import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}


// Updated ActionTypes Object
export const actionTypes = {
  GetPackageGroups: '[GetPackageGroups] Action',
  }
  
  // Updated Initial State
  const initialAuthState: IPackageGroupState = {
    packagegroups: undefined,
  }
  
  // Updated Department State Interface
  export interface IPackageGroupState {
    packagegroups?: any
  }
  
  // Updated Reducer Function
  export const reducer = persistReducer(
    { storage, key: 'v100-demo1-auth', blacklist: ['packagegroups'] },
    (state: IPackageGroupState = initialAuthState, action: ActionWithPayload<IPackageGroupState>) => {
      switch (action.type) {
        case actionTypes.GetPackageGroups: {
          const packagegroups = action.payload?.packagegroups
          return { ...state, packagegroups }
        }
        default:
          return state
      }
    }
  )
  
  // Updated Actions Object
  export const actions = {
    GetPackageGroups: (packagegroups:any) => ({ type: actionTypes.GetPackageGroups, payload: { packagegroups } }),
  }
  