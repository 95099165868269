import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { FallbackView } from "../../_metronic/partials";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import useAuthModules from "../hooks/useAuth";
import { hasPermission } from "../util/hasPermission";
import SelectBranchesPage from "../modules/select_branch/selectedBranchPage";
import SettingPage from "../modules/setting/SettingPage";
import SMSPage from "../modules/Template/sms/SMSPage";
import WhatsappPage from "../modules/Template/whatsapp/WhastappPage";
import EmailPage from "../modules/Template/email/EmailPage";

// Lazy loaded components
const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
const RolePage = lazy(() => import("../modules/role/RolePage"));
const MasterPage = lazy(() => import("../modules/master/MasterPage"));
const LeadsPage = lazy(() => import("../modules/leads/LeadsPage"));
const CompanyPage = lazy(() => import("../modules/company/CompanyPage"));

const PlansPage = lazy(() => import("../modules/plans/PlansPage"));
const AuthorizePage = lazy(
  () => import("../modules/autorizepage/AutorizePage")
);
// const UserPage = lazy(() => import('../modules/users/UserPage'));

//new modules
const BiometricLogPage = lazy(
  () => import("../modules/attendance/modules/biometric_log/BiometricLogPage")
);
const BranchesPage = lazy(() => import("../modules/branches/BranchesPage"));
const MembersPage = lazy(() => import("../modules/members/MembersPage"));
const financepage = lazy(() => import("../memberModules/finance/FinancePage"));
const posPage = lazy(() => import("../memberModules/POS/POSPage"));
const EnquiryPage = lazy(() => import("../memberModules/Enquiry/EnquiryPage"));
const MyMemberShip = lazy(() => import("../modules/MyMembership/MyMemberShip"));
const BiometricLog = lazy(
  () => import("../memberModules/biometriclog/BiometricLog")
);
const PackageFreezePage = lazy(
  () => import("../memberModules/mypackageFreeze/PackageFreezePage")
);
const MemberShipPage = lazy(
  () => import("../memberModules/MemberShip/MemberShipPage")
);
const attandancepage = lazy(
  () => import("../memberModules/myAttandance/MyAttandancepage")
);
const MembershipPage = lazy(
  () => import("../modules/memberships/MembershipPage")
);
const FinancePage = lazy(() => import("../modules/finance/FinancePage"));
const attendancePage = lazy(
  () => import("../modules/attendance/AttendancePage")
);
const EmployeesPage = lazy(() => import("../modules/employees/EmployeesPage"));
const ExpensePage = lazy(() => import("../modules/expenses/ExpensesPage"));
const POSPage = lazy(() => import("../modules/pos/PosPage"));
const Subscription = lazy(() => import("../modules/accounts/MembershipPage"));
const selectBranchPage = lazy(
  () => import("../modules/select_branch/selectedBranchPage")
);
const packagePeriodPage = lazy(
  () => import("../modules/master/modules/packagePeriod/PackagePeriodPage")
);
const Support = lazy(() => import("../modules/support/Support"));
const ReminderPage = lazy(
  () => import("../modules/quickLinks/modules/reminder/ReminderPage")
);
const QuickLinkpage = lazy(
  () => import("../modules/quickLinks/quickLinksPage")
);
const ActivityPage = lazy(
  () => import("../modules/quickLinks/modules/activity/ActivityPage")
);
const NotificationPage = lazy(
  () => import("../modules/quickLinks/modules/notifications/NotificationPage")
);

// Define your routes and their required permissions
const routes: any = [
  { path: "/dashboard", component: DashboardWrapper, permission: [] }, // Empty array means no permission required
  { path: "/crafted/pages/profile", component: ProfilePage, permission: [] },
  { path: "/crafted/pages/wizards", component: WizardsPage, permission: [] },
  // Define other routes with their respective modules and required permissions,
  { path: "/branches", component: BranchesPage, permission: [] }, // Empty array means no permission required
  { path: "/members", component: MembersPage, permission: [] }, // Empty array means no permission required
  { path: "/memberships", component: MembershipPage, permission: [] },
  { path: "/finance", component: FinancePage, permission: [] }, // Empty array means no permission required
  { path: "/employees", component: EmployeesPage, permission: [] }, // Empty array means no permission required
  { path: "/expenses", component: ExpensePage, permission: [] }, // Empty array means no permission required
  { path: "/pos", component: POSPage, permission: [] }, // Empty array means no permission required
  { path: "/attendance", component: attendancePage, permission: [] }, // Empty array means no permission required
  { path: "/master", component: MasterPage, permission: [] }, // Empty array means no permission required
  { path: "/leads", component: LeadsPage, permission: [] }, // Empty array means no permission required
  { path: "/select-branch", component: selectBranchPage, permission: [] },
  { path: "/company", component: CompanyPage, permission: [] },
  { path: "/setting", component: SettingPage, permission: [] },
  { path: "/template/sms", component: SMSPage, permission: [] },
  { path: "/template/whatsapp", component: WhatsappPage, permission: [] },
  { path: "/template/email", component: EmailPage, permission: [] },
  { path: "/usermember/membership", component: MemberShipPage, permission: [] },
  { path: "/usermember/finance", component: financepage, permission: [] },
  { path: "/usermember/pos", component: posPage, permission: [] },
  { path: "/usermember/enquiry", component: EnquiryPage, permission: [] },
  { path: "/membership/plan/", component: MyMemberShip, permission: [] },
  {
    path: "/usermember/myattandance/biometriclog",
    component: BiometricLog,
    permission: [],
  },
  {
    path: "/usermember/packagefreeze",
    component: PackageFreezePage,
    permission: [],
  },
  {
    path: "/usermember/myattandance",
    component: attandancepage,
    permission: [],
  },

  // {
  //   path: "/roles",
  //   component: RolePage,
  //   permission: [],
  //   //permission: [{ module_name: "Role Management", action: "view" },],
  // },

  {
    path: "/crafted/widgets",
    component: WidgetsPage,
    permission: [], // No specific permission required for this route
  },
  {
    path: "/crafted/account",
    component: AccountPage,
    permission: [], // No specific permission required for this route
  },
  {
    path: "/crafted/user/subscription",
    component: Subscription,
    permission: [], // No specific permission required for this route
  },
  {
    path: "/menu-test",
    component: MenuTestPage,
    permission: [], // No specific permission required for this route
  },
  {
    path: "/support",
    component: Support,
    permission: [], // No specific permission required for this route
  },
  {
    path: "/quicklinks",
    component: QuickLinkpage,
    permission: [], // No specific permission required for this route
  },
  {
    path: "/pages/unauthorized",
    component: AuthorizePage,
    permission: [], // No specific permission required for this route
  },
  // {
  //   path: "/reminder",
  //   component: ReminderPage,
  //   permission: [], // No specific permission required for this route
  // },
  // {
  //   path: "/activity",
  //   component: ActivityPage,
  //   permission: [], // No specific permission required for this route
  // },
  // {
  //   path: "/notification",
  //   component: NotificationPage,
  //   permission: [], // No specific permission required for this route
  // },
];

export function PrivateRoutes() {
  const location = useLocation();
  // let isBranch = false
  const allowedPaths = ["/dashboard", "/branches", "/select-branch"];

  let roles: any = [];
  let role_id = ""; // Utility function to check if the user has a specific permission
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  let isBranch: any = JSON.parse(selectedBranch);
  // if(activeBranch){
  //   isBranch = true
  // }
  // console.log(isBranch, "iiiiiiiiiiiiiiiii");
  let selectedBranchList: any = localStorage.getItem("ActiveBranchList");
  let activeBranchList: any = JSON.parse(selectedBranchList);
  // console.log("activeBranchList--------->", activeBranchList);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch location={location}>
        {routes.map(({ path, component, permission }: any) => {
          if (!isBranch && activeBranchList && allowedPaths.includes(path)) {
            // console.log("innnnnnnnnnnnnnnnn", path);
            return (
              <Switch location={location}>
                <Route path="/dashboard" component={DashboardWrapper} />
                <Route path="/branches" component={BranchesPage} />
                <Route path="/select-branch" component={selectBranchPage} />
                <Redirect to="/select-branch" />
              </Switch>
            );
          } else if (!isBranch && !activeBranchList) {
            return (
              <Switch location={location}>
                <Route path="/branches" component={selectBranchPage} />
                <Redirect to="/branches" />
              </Switch>
            );
          } else {
            return <Route key={path} path={path} component={component} />;
          }
          // You may want to handle unauthorized access differently, like redirecting to a 403 page.

          // if(permission.length === 0 || hasPermission(roles, role_id, permission[0]?.module_name, permission[0]?.action)) {
          //   console.log("outttttttttttttt",path,permission)
          //   return <Route key={path} path={path} component={component} />;

          // }

          // You may want to handle unauthorized access differently, like redirecting to a 403 page.
        })}

        {isBranch &&
          activeBranchList &&
          routes.map(({ path, component, permission }: any) => {
            if (
              permission.length === 0 ||
              hasPermission(
                roles,
                role_id,
                permission[0]?.module_name,
                permission[0]?.action
              )
            ) {
              // console.log("outttttttttttttt", path, permission);
              return <Route key={path} path={path} component={component} />;
            }

            // You may want to handle unauthorized access differently, like redirecting to a 403 page.
            return null;
          })}
        {/* Add fallback routes like Redirects here */}

        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
