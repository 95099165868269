import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

// Updated ActionTypes Object
export const actionTypes = {
  GetWhatsapp: "[GetWhatsapp] Action",
};

// Updated Initial State
const initialAuthState: IPackagePeriodState = {
  Whatsapp: undefined,
};

// Updated Department State Interface
export interface IPackagePeriodState {
  Whatsapp?: any;
}

// Updated Reducer Function
export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Whatsapp"] },
  (
    state: IPackagePeriodState = initialAuthState,
    action: ActionWithPayload<IPackagePeriodState>
  ) => {
    switch (action.type) {
      case actionTypes.GetWhatsapp: {
        const Whatsapp = action.payload?.Whatsapp;
        return { ...state, Whatsapp };
      }
      default:
        return state;
    }
  }
);

// Updated Actions Object
export const actions = {
  GetWhatsapp: (Whatsapp: any) => ({
    type: actionTypes.GetWhatsapp,
    payload: { Whatsapp },
  }),
};
