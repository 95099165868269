import React from "react";
import { Route, Switch } from "react-router-dom";
// import Company from "./Company";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import WhatsappList from "./componants/WhatsappList";
import { useIntl } from "react-intl";
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "Whatsapp",
    path: "/template/whatsapp",
    isSeparator: false,
    isActive: false,
  },
];
const WhatsappPage: React.FC = () => {
  const intl = useIntl();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  return (
    <>
      <Switch>
        <Route path="/template/whatsapp">
          <PageTitle>{intl.formatMessage({ id: "COMMON.WHATSAPP" })}</PageTitle>
          <WhatsappList />
        </Route>
      </Switch>
    </>
  );
};

export default WhatsappPage;
