export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);

  if (!current || !url) {
    return false;
  }

  // Split the paths by '/' to ensure matching entire segments
  const currentSegments = current.split("/").filter(Boolean); // Filter out empty segments
  const urlSegments = url.split("/").filter(Boolean);

  // Check if the current path starts with the url path
  if (urlSegments.length > currentSegments.length) {
    return false;
  }

  // Match each segment of the URL with the corresponding segment in the current path
  return urlSegments.every(
    (segment, index) => segment === currentSegments[index]
  );
}
