import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
export const exportToXLS = (filteredData: any, DownloadFileName: any) => {
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate buffer
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  // Create Blob and download
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${DownloadFileName}.xlsx`);
};
