import { useEffect, useState } from "react";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import MemberMenu from "./MemberMenu";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLang } from "../../../i18n/Metronici18n";
// import * as HeaderRedux from "../header/redux/HeadersRedux";
export function AsideMenuMain() {
  const intl = useIntl();
  const data: any = localStorage.getItem("activePlanModules");
  const User: any = localStorage.getItem("activeUserType");
  const activeModuleList = JSON.parse(data);
  const ActiveUser = JSON.parse(User);
  const [activeModule, setActiveModule] = useState<any[]>(activeModuleList);
  // const [activeMainMenu, setActiveMainMenu] = useState<string | null>(null);
  // const [openSubMenus, setOpenSubMenus] = useState<{ [key: string]: string[] }>(
  //   {}
  // );
  const lang = useLang();
  const language: any = useSelector((state: any) => state.headers.selectedLang);
  // console.log(language, "select language");
  useEffect(() => {
    // Read from localStorage or API
    const fetchData = () => {
      const data = localStorage.getItem("activePlanModules");

      if (data) setActiveModule(JSON.parse(data));
    };

    fetchData();
    window.addEventListener("storage", fetchData);

    return () => {
      window.removeEventListener("storage", fetchData);
    };
  }, [lang]);

  return (
    <>
      {/* <AsideMenuItem
        to={`/dashboard`}
        icon="/media/icons/duotune/icon/dashboard.svg"
        title={intl.formatMessage({ id: "COMMON.DASHBOARD" })}
      /> */}
      {ActiveUser === "member" && <MemberMenu />}
      {ActiveUser !== "member" &&
        activeModule?.map((x: any, i: any) => {
          // console.log(x, "dsdsf");
          if (x.displayStauts === 1) {
            const mainMenuKey = x.GroupSlug;
            if (x.GroupName === "Dashboard") {
              return (
                <AsideMenuItem
                  key={i}
                  to={`/${x.GroupSlug.toLowerCase()}`}
                  title={x.GroupName}
                  icon="/media/icons/duotune/icon/dashboard.svg"
                  // onClick={() =>
                  //   handleMenuItemClick(x.GroupSlug, subMenuKey)
                  // } // Close all others when clicked
                />
              );
            }
            return (
              <AsideMenuItemWithSub
                key={i}
                to={`/${mainMenuKey}`}
                icon={`/media/icons/duotune/icon/${x.iconClass}` + ".svg"}
                title={x.GroupName}
                fontIcon="bi-app-indicator"
                // isOpen={activeMainMenu === mainMenuKey}
                // onClick={() => handleMainMenuClick(mainMenuKey)}
              >
                {x.modulesdata.map((y: any, i: any) => {
                  if (y.moduleDisplayStauts === 1) {
                    // const subMenuKey = y.moduleName;
                    return (
                      <AsideMenuItemWithSub
                        key={i}
                        to={`/${mainMenuKey}/${y.moduleSlug}`}
                        icon={`/media/icons/duotune/icon/${
                          y.iconClass ? y.iconClass : "y.moduleSlug"
                        }.svg`}
                        title={y.moduleName}
                        fontIcon="bi-app-indicator"
                        // isOpen={
                        //   !!openSubMenus[mainMenuKey]?.includes(subMenuKey)
                        // }
                        // onClick={() =>
                        //   handleSubMenuClick(mainMenuKey, subMenuKey)
                        // }
                      >
                        {y.ActionList.map((z: any, i: any) => {
                          if (z.displayStauts === 1) {
                            return (
                              <AsideMenuItem
                                key={i}
                                to={`/${mainMenuKey.toLowerCase()}/${y.moduleSlug.toLowerCase()}${z.actionURL.toLowerCase()}`}
                                title={z.title}
                                hasBullet={true}
                                // onClick={() =>
                                //   handleMenuItemClick(mainMenuKey, subMenuKey)
                                // } // Close all others when clicked
                              />
                            );
                          }
                        })}
                      </AsideMenuItemWithSub>
                    );
                  }
                })}
              </AsideMenuItemWithSub>
            );
          } else {
            return x?.modulesdata?.map((y: any, i: any) => {
              if (y.moduleDisplayStauts === 1) {
                const subMenuKey = y.moduleName;

                return (
                  <AsideMenuItemWithSub
                    key={i}
                    to={`/${x.GroupSlug}`}
                    icon={`/media/icons/duotune/icon/${x.GroupSlug}` + ".svg"}
                    title={y.moduleName}
                    fontIcon="bi-app-indicator"
                    // isOpen={!!openSubMenus[x.GroupSlug]?.includes(subMenuKey)}
                    // onClick={() => handleSubMenuClick(x.GroupSlug, subMenuKey)}
                  >
                    {y.ActionList.map((z: any, i: any) => {
                      if (z.displayStauts === 1) {
                        return (
                          <AsideMenuItem
                            key={i}
                            to={`/${x.GroupSlug.toLowerCase()}`}
                            title={z.title}
                            hasBullet={true}
                            // onClick={() =>
                            //   handleMenuItemClick(x.GroupSlug, subMenuKey)
                            // } // Close all others when clicked
                          />
                        );
                      }
                    })}
                  </AsideMenuItemWithSub>
                );
              }
            });
          }
        })}
      {/* <AsideMenuItemWithSub
        to="/support-center/"
        title="Support"
        icon="/media/icons/duotune/technology/teh006.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to="/support-center/list-tickets"
          title="view all"
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to="/reminder/"
        title="Reminder"
        icon="/media/icons/duotune/general/gen013.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to="/reminder/list-reminder"
          title="view all"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/activity/"
        title="Activities"
        icon="/media/icons/duotune/general/gen002.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to="/activity/list-activity"
          title="view all"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/notification/"
        title="notification"
        icon="/media/icons/duotune/general/gen007.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to={`/notification/list`}
          title="view all"
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
    </>
  );
}
