import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetUserRemainingBalances: "[GetUserRemainingBalances] Action",
};

const initialAuthState: ICenterState = {
  UserremainingBalances: undefined,
};

export interface ICenterState {
  UserremainingBalances?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["UserremainingBalances"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetUserRemainingBalances: {
        const UserremainingBalances = action.payload?.UserremainingBalances;
        return { ...state, UserremainingBalances };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetUserRemainingBalances: (UserremainingBalances: string) => ({
    type: actionTypes.GetUserRemainingBalances,
    payload: { UserremainingBalances },
  }),
};
