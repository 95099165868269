import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetExpires: "[GetExpires] Action",
};

const initialAuthState: ICenterState = {
  Expires: undefined,
};

export interface ICenterState {
  Expires?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Expires"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetExpires: {
        const Expires = action.payload?.Expires;
        return { ...state, Expires };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetExpires: (Expires: string) => ({
    type: actionTypes.GetExpires,
    payload: { Expires },
  }),
};
