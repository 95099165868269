import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../redux/AuthCRUD";
import { useIntl } from "react-intl";
import swal from "sweetalert";

const initialValues = {
  email_mobile: "admin@demo.com",
  domain_subdomain: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email_mobile: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

export function ForgotPassword() {
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        requestPassword(values)
          .then((response: any) => {
            // console.log(response, "dsfdf");

            if (response.status === 200) {
              // setHasErrors(false);
              // setLoading(false);
              const data = response?.data?.data;
              localStorage.setItem("forgetpassword", JSON.stringify(data));
              swal(response?.data?.replyMsg, { icon: "success" });
              setTimeout(() => {
                history.push(`/auth/reset-user-password`);
              }, 2000);
            } else {
              // setHasErrors(false);
              // setLoading(false);
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 400) {
              const errorMsg = err.response.data.replyMsg;
              // console.log(errorMsg, "dsfd");
              // setHasErrors(true);
              setStatus(errorMsg);
            } else {
              // console.log(err);
              // setHasErrors(true);
              setStatus("An unexpected error occurred. Please try again.");
            }
          })
          .finally(() => {
            setLoading(false);
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="w-lg-500px bg-white rounded shadow-sm p-5 p-lg-6 mx-auto">
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "MODULE.AUTH.FORGOTPASSWORD" })}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            {intl.formatMessage({ id: "MODULE.AUTH.RESETPASSWORD" })}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {/* {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Sorry, looks like there are some errors detected, please try
              again.
            </div>
          </div>
        )} */}

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="required form-label fw-bolder text-gray-900 fs-6">
            {intl.formatMessage({ id: "MODULE.AUTH.EMAIL" })}
          </label>
          <input
            type="email"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("email_mobile")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.email_mobile && formik.errors.email_mobile,
              },
              {
                "is-valid":
                  formik.touched.email_mobile && !formik.errors.email_mobile,
              }
            )}
          />
          {formik.touched.email_mobile && formik.errors.email_mobile && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email_mobile}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            <span className="indicator-label">
              {" "}
              {intl.formatMessage({ id: "COMMON.SUBMIT" })}
            </span>
            {loading && (
              <span className="indicator-progress">
                {intl.formatMessage({ id: "COMMON.PLEASEWAIT" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({ id: "MODULE.AUTH.CANCEL" })}
            </button>
          </Link>{" "}
        </div>
        {/* end::Form group */}
      </form>
    </div>
  );
}
