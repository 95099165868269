import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import InvoiceList from "../InvoiceList";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetInvoices: "[GetInvoices] Action",
};

const initialAuthState: ICenterState = {
  invoices: undefined,
};

export interface ICenterState {
  invoices?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["invoices"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetInvoices: {
        const invoices = action.payload?.invoices;
        return { ...state, invoices };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetInvoices: (invoices: string) => ({
    type: actionTypes.GetInvoices,
    payload: { invoices },
  }),
};
