/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
// import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Link } from "react-router-dom";

const QuickLinks: FC = () => (
  <div
    className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px"
    data-kt-menu="true"
  >
    <div
      className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10"
      style={{
        backgroundImage: `url('${toAbsoluteUrl("/media/misc/pattern-1.jpg")}')`,
      }}
    >
      <h3 className="text-white fw-bold mb-3">Quick Links</h3>

  {/*     <span className="badge bg-primary py-2 px-3">25 pending tasks</span> */}
    </div>

    <div className="row g-0">
      <div className="col-6">
        <Link
          to="/quicklinks/reminder/view"
          className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
        >
          <KTSVG
            path="/media/icons/duotune/icon/reminder.svg"
            className="svg-icon-3x svg-icon-primary mb-2"
          />
          <span className="fs-5 fw-bold text-gray-800 mb-0">Reminder</span>
        </Link>
      </div>

      <div className="col-6">
        <Link
          to="/quicklinks/activities/view"
          className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
        >
          <KTSVG
            path="/media/icons/duotune/icon/activites.svg"
            className="svg-icon-3x svg-icon-primary mb-2"
          />
          <span className="fs-5 fw-bold text-gray-800 mb-0">Activity</span>
        </Link>
      </div>

      <div className="col-6">
        <Link
          to="/quicklinks/notification/view"
          className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
        >
          <KTSVG
            path="/media/icons/duotune/icon/notification.svg"
            className="svg-icon-3x svg-icon-primary mb-2"
          />
          <span className="fs-5 fw-bold text-gray-800 mb-0">Notifications</span>
        </Link>
      </div>

      {/* <div className="col-6">
        <Link
          to="/quicklinks/activities/view"
          className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
        >
          <KTSVG
            path="/media/icons/duotune/icon/activites.svg"
            className="svg-icon-3x svg-icon-primary mb-2"
          />
          <span className="fs-5 fw-bold text-gray-800 mb-0">Quick Links</span>
          <span className="fs-7 text-gray-400">Quick Links</span>
        </Link>
      </div> */}
    </div>

    {/* <div className='py-2 text-center border-top'>
      <Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>
        View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
      </Link>
    </div> */}
  </div>
);

export { QuickLinks };
