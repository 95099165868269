import React from "react";
import { Card, Dropdown, Badge } from "react-bootstrap";

const DashboardCard = ({ title, subtitle, date, color, slug }: any) => {
  return (
    <div>
      <div className=" d-flex justify-content-between w-100">
        <span className="ps-1 fs-2 my-1 text-muted">
          {" "}
          {subtitle !== null ? `${title} ${subtitle}` : `${title} 0`}
        </span>
        <span
          className={`svg-icon svg-icon-3x svg-icon-${color} text-end my-2 mx-3`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              x="8"
              y="9"
              width="3"
              height="10"
              rx="1.5"
              fill="black"
            ></rect>
            <rect
              opacity="0.5"
              x="13"
              y="5"
              width="3"
              height="14"
              rx="1.5"
              fill="black"
            ></rect>
            <rect
              x="18"
              y="11"
              width="3"
              height="8"
              rx="1.5"
              fill="black"
            ></rect>
            <rect
              x="3"
              y="13"
              width="3"
              height="6"
              rx="1.5"
              fill="black"
            ></rect>
          </svg>
        </span>
      </div>

      {/* <div className={`text-${color} fw-bold fs-6 my-1`}>{title}</div> */}
      <div className={`text-muted d-flex `}>
        <div style={{ width: "fit-content" }}>
          <label className=" fs-6 text-muted">{slug ? "" : " "}</label>
        </div>
        <div className="ps-1" style={{ width: "fit-content" }}>
          {date}
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
