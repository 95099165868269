import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import {
  requestMemberPassword,
  requestPassword,
  resetMemberPassword,
  resetUserPassword,
} from "../../redux/AuthCRUD";
import { result } from "lodash";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Select from "react-select";
import { useIntl } from "react-intl";
import swal from "sweetalert";
const initialValues = {
  otp: "",
  password: "",
  confirm_password: "",
};

const forgotPasswordSchema = Yup.object().shape({
  otp: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export function ResetpasswordUser() {
  const data1: any = localStorage.getItem("forgetpassword");
  const data = JSON.parse(data1);
  // console.log(data, "forgetpassword");
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<any>();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //   console.log(values);
      setLoading(true);
      setHasErrors(undefined);
      const formdata = { ...values, ...data };
      setTimeout(() => {
        resetUserPassword(formdata)
          .then((response) => {
            // console.log(response, "response----");
            if (response.status === 200) {
              swal(response?.data?.replyMsg, { icon: "success" });
              history.push("/auth/login");
              setLoading(false);
              setHasErrors(false);
              localStorage.removeItem("forgetpassword");
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 400) {
              const errorMsg = err.response?.data?.replyMsg;
              // console.log(err.response.data.replyMsg);
              setError(errorMsg);
              //   console.log(errorMsg);
              // setHasErrors(true);
              setStatus(errorMsg);
            } else {
              // console.log(err);
              // setHasErrors(true);
              setStatus("An unexpected error occurred. Please try again.");
            }
          })
          .finally(() => {
            setLoading(false);
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="w-lg-500px bg-white rounded shadow-sm p-5 p-lg-6 mx-auto">
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "MODULE.AUTH.HEADING3" })}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}

          {/* <div className="text-success fw-bold fs-4">
            {intl.formatMessage({ id: "MODULE.AUTH.HEADING4" })}
          </div> */}
          <div className="text-gray-400 fw-bold fs-4">
            {intl.formatMessage({ id: "MODULE.AUTH.HEADING5" })}
          </div>
          {/* end::Link */}
        </div>

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}
        {/* end::Title */}

        {/* begin::Form group */}

        <div className="fv-row mb-10">
          <label className="required form-label fw-bolder text-gray-900 fs-6">
            {" "}
            {intl.formatMessage({ id: "MODULE.AUTH.OTP" })}
          </label>
          <input
            type="number"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("otp")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              //   { "is-invalid": formik.touched.otp && formik.errors.otp },
              //   {
              //     "is-valid": formik.touched.otp && !formik.errors.otp,
              //   }
            )}
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.otp}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row mb-10">
          <label className="required form-label fw-bolder text-gray-900 fs-6">
            {intl.formatMessage({ id: "MODULE.AUTH.PASSWORD" })}
          </label>
          <div className="position-relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder=""
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
                //   { "is-invalid": formik.touched.otp && formik.errors.otp },
                //   {
                //     "is-valid": formik.touched.otp && !formik.errors.otp,
                //   }
              )}
            />
            <span
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
              style={{ cursor: "pointer" }}
              onClick={() => setShowpassword(!showPassword)}
            >
              {showPassword ? (
                <FaEyeSlash className="fs-4 text-primary me-3" />
              ) : (
                <FaEye className="fs-4 text-muted me-3" />
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
          {error?.password && (
            <div className="text-danger">{error.password}</div>
          )}
        </div>
        <div className="fv-row mb-10">
          <label className="required form-label fw-bolder text-gray-900 fs-6">
            {intl.formatMessage({ id: "MODULE.AUTH.CPASSWORD" })}
          </label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("confirm_password")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              //   { "is-invalid": formik.touched.otp && formik.errors.otp },
              //   {
              //     "is-valid": formik.touched.otp && !formik.errors.otp,
              //   }
            )}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert ">{formik.errors.confirm_password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            <span className="indicator-label">
              {" "}
              {intl.formatMessage({ id: "COMMON.SUBMIT" })}
            </span>
            {loading && (
              <span className="indicator-progress">
                {intl.formatMessage({ id: "COMMON.PLEASEWAIT" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({ id: "MODULE.AUTH.CANCEL" })}
            </button>
          </Link>{" "}
        </div>
        {/* end::Form group */}
      </form>
    </div>
  );
}
