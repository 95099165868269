import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import InvoiceList from "../InvoiceList";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetMemberAttendences: "[GetMemberAttendences] Action",
};

const initialAuthState: ICenterState = {
  UserMemberattendences: undefined,
};

export interface ICenterState {
  UserMemberattendences?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["UserMemberattendences"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetMemberAttendences: {
        const UserMemberattendences = action.payload?.UserMemberattendences;
        return { ...state, UserMemberattendences };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetMemberAttendences: (UserMemberattendences: string) => ({
    type: actionTypes.GetMemberAttendences,
    payload: { UserMemberattendences },
  }),
};
