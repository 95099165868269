import axios from "axios";
import { AuthModel } from "../models/AuthModel";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/user/get-user`;
export const LOGIN_URL = `${API_URL}/v1/user/login`;
export const LOGIN_MEMBER_URL = `${API_URL}/v1/centermember/login`;
export const REGISTER_URL = `${API_URL}/v1/user/registration`;
export const REQUEST_PASSWORD_URL = `${API_URL}/v1/user/resetpasswordotp`;
export const REQUEST_MEMBER_PASSWORD_URL = `${API_URL}/v1/centermember/resetpasswordotp`;
export const RESET_MEMBER_PASSWORD_URL = `${API_URL}/v1/centermember/resetpassword`;
export const RESET_USER_PASSWORD_URL = `${API_URL}/v1/user/resetpassword`;
export const GET_ALL_PLANS = `${API_URL}/v1/common/planlist/`;
export const VALIDATE_COUPON = `${API_URL}/v1/coupon/validate`;
export const LOGIN_OTP = `${API_URL}/v1/user/otp_login`;
export const VERIFY_OTP = `${API_URL}/v1/user/otp_verify`;
export const GET_ADMIN_SETTINGS = `${API_URL}/v1/admin/settings/list`;
export const GET_PLAN_MODULE_DETAILS_LIST = `${API_URL}/v1/plan/groupmoduleactionlist/2e7041af-8a8f-47b3-8373-bb8a3b56`;
export const GET_COUNTRY_CODE_LIST = `${API_URL}/v1/common/countryandcourencylist`;
export const GET_TIME_JONE_LIST = `${API_URL}/v1/common/timezonelist`;
export const GET_ACTIVE_BRANCHES = `${API_URL}/v1/branch/activelist`;
export const GET_ACTIVE_BRANCH = `${API_URL}/v1/setting/branchdetails`;

// Server should return AuthModel
export function login(data: any) {
  // console.log("iside login");
  return axios.post(LOGIN_URL, data).catch((error) => {
    return error.response;
  });
}
export function loginMember(data: any) {
  // console.log("iside login");
  return axios.post(LOGIN_MEMBER_URL, data).catch((error) => {
    return error.response;
  });
}
// Server should return AuthModel
export function refreshLogin(data: any) {
  // console.log("iside with refresh login");
  return axios.post(LOGIN_URL, data).catch((error) => {
    return error.response;
  });
}

// Server should return AuthModel
export function register(userData: any) {
  return axios.post<AuthModel>(REGISTER_URL, userData).catch((error) => {
    return error.response;
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: any) {
  return axios.put<{ result: boolean }>(REQUEST_PASSWORD_URL, email);
}
// export function requestMemberPassword(email: string) {
//   return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email });
// }
export function requestMemberPassword(email: any) {
  return axios.put<{ result: boolean }>(REQUEST_MEMBER_PASSWORD_URL, email);
}
export function resetMemberPassword(data: any) {
  return axios.put<any>(RESET_MEMBER_PASSWORD_URL, data);
}
export function resetUserPassword(data: any) {
  return axios.put<any>(RESET_USER_PASSWORD_URL, data);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios

  return axios.get<any>(GET_USER_BY_ACCESSTOKEN_URL);
}

export function getPlanModuleList() {
  return axios.get(GET_PLAN_MODULE_DETAILS_LIST);
}
export function GetActiveBranches() {
  return axios.get(GET_ACTIVE_BRANCHES);
}
export function GetActiveBranch(data: any) {
  return axios.get(`${GET_ACTIVE_BRANCH}/${data?.iBranchUUID}`);
}
//getting all country code
export function getCountryCodeList() {
  return axios.get(GET_COUNTRY_CODE_LIST);
}
//getting all timezone list
export function getTimeZoneList() {
  return axios.get(GET_TIME_JONE_LIST);
}

//getting all roles
export function GetPlans(data: any) {
  return axios.get(`${GET_ALL_PLANS}${data.type}`);
}

export function validateCoupon(data: any) {
  return axios.post(VALIDATE_COUPON, data).catch((error) => {
    return error.response;
  });
}

export function loginwithOTP(data: any) {
  return axios.post(LOGIN_OTP, data).catch((error) => {
    return error.response;
  });
}

export function verfiyOTP(data: any) {
  return axios.post(VERIFY_OTP, data).catch((error) => {
    return error.response;
  });
}
export function GetAdminSettings() {
  return axios.get(GET_ADMIN_SETTINGS);
}
