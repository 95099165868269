/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useEffect } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
// import { MenuItem } from './MenuItem'
import * as HeaderRedux from "../header/redux/HeadersRedux";
import { useLang, setLanguage } from "../../../i18n/Metronici18n";
import { getallmodules } from "./redux/HeadersCRUD";

const Languages: FC = () => {
  const dispatch = useDispatch();
  const languagesnew: any = useSelector(
    (state: any) => state.headers.languageList
  );
  const lang = useLang();
  const currentLanguage = languagesnew?.find(
    (x: any) => x.languageSlug === lang
  );
  const activemodule = async () => {
    const response = await getallmodules();
    if (response?.status === 200) {
      const data = JSON.stringify(response?.data?.data);
      // console.log(response, "acitvemodule");
      localStorage.setItem("activePlanModules", data);
      window.dispatchEvent(new Event("storage"));
    } else {
      console.log(response?.data?.replyMsg);
    }
  };
  useEffect(() => {
    if (lang) {
      activemodule();
    }
  }, [lang]);
  // console.log(lang, "language");
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
      data-kt-menu="true"
    >
      {languagesnew?.map((l: any) => (
        <div
          className="menu-item px-3"
          key={l.languageSlug}
          onClick={() => {
            dispatch(HeaderRedux.actions.SetLanguage(l.languageSlug));
            setLanguage(l.languageSlug);
          }}
        >
          <a
            href="#"
            className={clsx("menu-link d-flex px-5", {
              active: l.languageName === currentLanguage?.languageName,
            })}
          >
            <span className="symbol symbol-20px me-4">
              <img
                className="rounded-1"
                src={toAbsoluteUrl("/media/flags/india.svg")}
                alt="metronic"
              />
            </span>
            {l.languageName}
          </a>
        </div>
      ))}
    </div>
  );
};

export { Languages };
