import { FC } from "react";
import { useIntl } from "react-intl";

const Error401: FC = () => {
  const intl = useIntl();
  return (
    <>
      <h1 className="fw-bolder fs-4x text-gray-700 mb-10">
        {intl.formatMessage({ id: "ERROR.401" })}
      </h1>

      <div className="fw-bold fs-3 text-gray-400 mb-15">
        {intl.formatMessage({ id: "ERROR.401.MESSAGE" })}
      </div>
    </>
  );
};

export { Error401 };
